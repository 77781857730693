<template>
  <app-bar title="Add Item" backButton>
    <v-main class="add-item-view">
      <Page>
        <div class="d-flex;" style="width: 100%; margin-top: 40px;">
          <v-text-field
            v-model="filterText"
            placeholder="Arama"
            clearable
            filled
            rounded
            prepend-inner-icon="mdi-magnify"
          />
          <template v-for="group in filteredItems">
            <div
              class="mt-2"
              v-if="!group.is_scrollable"
              :key="group.id"
            >
              <div class="group-title">
                {{$t(group.name)}}
              </div>
              <v-col class="p-0">
                <add-list-item
                  v-for="(item) in group.fields"
                  :key="item.id"
                  class="list-item my-3 list-item-round py-3"
                  :content="item"
                  :click="() => $router.push({name: 'Add Item', params: {itemId: item.id } })"
                />
              </v-col>
            </div>
            <div
              class="mt-2"
              v-else
              :key="group.id"
            >              <div class="group-title">
                {{$t(group.name)}}
              </div>
              <v-col class="p-0" style="max-height: 600px;">
                <div class="grouped-scrollable-list">
                  <add-list-item
                    v-for="(item) in group.fields"
                    :key="item.id"
                    class="my-3 list-item-round py-3"
                    :class="group.fields.length > 3 ? 'list-item' : ''"
                    :style="group.fields.length > 3 ? 'width: 90%;' : 'width: 100%;'"
                    :content="item"
                    :click="() => $router.push({name: 'Add Item', params: {itemId: item.id } })"
                  />
                </div>
              </v-col>
            </div>
          </template>
        </div>
      </Page>
    </v-main>
  </app-bar>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Page from '@/components/Page.vue'
import AddListItem from '@/components/AddListItem.vue'
import axios from '@/utils/axios'

export default {
  components: {
    AppBar,
    Page,
    AddListItem
  },
  data () {
    return {
      isSelectingProfileImage: false,
      items: [],
      filterText: '',
      filteredItems: []
    }
  },
  mounted () {
    this.getItemTypes()
  },
  methods: {
    getItemTypes () {
      return new Promise((resolve, reject) => {
        axios.get('/field_type_groups')
          .then((res) => {
            if (res?.data?.data) {
              this.items = res.data.data
              this.filteredItems = res.data.data
              // this.items = res.data.data.map((item) => ({
              //   id: item.id,
              //   name: item.name,
              //   icon: item.icon_url,
              //   regex: item.regex,
              //   prefix: item.prefix,
              //   postfix: item.postfix,
              //   input_type_id: item.input_type_id
              // }))
            }
          })
      })
    }
  },
  watch: {
    filterText (newVal, oldVal) {
      if (newVal === '' || !newVal) {
        this.filteredItems = this.items
      } else {
        this.filteredItems = []
        this.items.forEach((groupArray) => {
          const arr = groupArray.fields.filter(x => this.$t(x.name).toLowerCase().trim().includes(this.filterText.toLowerCase().trim()))
          if (arr.length > 0) {
            const groupAr = { ...groupArray }
            groupAr.fields = arr
            this.filteredItems.push(groupAr)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.edit-profile-view {
  .v-text-field--filled.v-input--dense .v-label:not(.v-label--active) {
      margin-top: 6px !important;
  }
}

</style>

<style lang="scss" scoped>
.list-item-round {
  border-radius: 0.9rem !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.grouped-scrollable-list {
  display: flex;
  overflow: auto;
  max-height: 300px;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 10px;

  .list-item {
    margin-right: 10px;
  }
}
</style>

<style>
  .group-title {
    padding-left: 15px;
    font-weight: 500;
    font-size: 20px !important;
  }
</style>
