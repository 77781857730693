<template>
  <v-card>
    <div class="px-5 py-5">
      <h3 class="text-center mb-5">Kişisel Verilerin İşlenmesine İlişkin Açık Rıza</h3>
      <p>
        IDY Teknoloji Bilişim Limited Şirketi (“Şirket”) nezdinde işlenen kişisel verilerinizin, 6698 sayılı
Kişisel Verilerin Korunması Kanununda işleme şartı olarak belirtilen hallerin varlığı haricinde,
aşağıda belirtilen şekillerde işlenebilmesi için açık rızanız gerekmektedir. Lütfen aşağıdaki açık
rıza metnini okuyup, rızanıza uygun olarak onaylayıp onaylamama konusunda seçim yapınız.
      </p>
      <p class="mt-3">
        Ad, soyad, doğum tarihi, cinsiyet, T.C. kimlik numarası gibi kimlik verilerim ile, e-posta adresi,
adres, konum, telefon numarası, cihaz bilgileri, internet sitesi kullanım verileri, alışveriş geçmişi
bilgileri, anket bilgileri, kampanya katılım bilgileri, internet sitesi kayıtları, kullanıcı oturum
bilgileri, meslek, sipariş bilgileri ve Şirketinizle paylaştığım diğer kişisel verilerimin; kampanya,
analitik çalışma, reklam, tanıtım, promosyon, iş ortaklıklarının planlanması, tedarik yönetiminin
yürütülmesi amaçlarıyla işlenmesine ve gerek yurt içinde gerek de yurt dışında bulunan üçüncü
kişiler ile paylaşılmasını onaylıyorum.
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  mounted () {
    if (window.location.pathname.includes('/consent')) {
      document.head.innerHTML += `
      <style>
        html, body {
            overflow-y: auto !important;
          }
        </style>
      `
    }
  }
}
</script>
