<template>
<v-container fill-height>
  <v-card flat width="100%" style="margin-top: -5px;">
    <v-card-title class="d-flex justify-center">
      <v-img src="@/assets/logo.svg" max-width="10rem" class="justify-center"></v-img>
    </v-card-title>
    <v-card-title>
      {{$t('resetPassword')}}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
      >
        <v-container>
          <v-text-field
            v-model="form.newPassword"
            :label="$t('newPassword')"
            :rules="[rules.required, rules.min6]"
            type="password"
            required
          />
          <v-text-field
            v-model="form.reNewPassword"
            :label="$t('newPasswordConfirm')"
            :rules="[rules.required, rules.min6, rules.confirmPassword]"
            type="password"
            required
          />
          <div v-if="error" class="subtitle-2 red--text">
            {{$t(`errorCodes.${[error.code]}`)}}
          </div>
          <v-row class="justify-end mt-2">
            <v-btn
              @click="submitForm"
              justify-end
              :loading="loading"
            >{{$t('confirm')}}</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
export default {
  data () {
    return {
      isFormValid: false,
      form: {
        newPassword: '',
        reNewPassword: '',
        token: ''
      },
      rules: {
        required: value => !!value || this.$t('formErrors.required'),
        min6: value => {
          return (value && value.length >= 6) || this.$t('formErrors.min6')
        },
        confirmPassword: value => value === this.form.newPassword || this.$t('newPasswordNotMatch')
      }
    }
  },
  computed: {
    loading () {
      return (this.$store.state.auth.resetPassword.status === 'loading')
    },
    error () {
      return this.$store.state.auth.resetPassword.error
    }
  },
  methods: {
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    submitForm () {
      if (!this.$refs.form.validate()) return null

      this.$store.dispatch('auth/resetPassword', {
        newPassword: this.form.newPassword,
        token: this.$route.params.token
      }).then((a) => {
        this.$refs.form.reset()
        this.$router.replace({ name: 'Login' })
      })
    }
  }
}
</script>
