<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <bottom-navigation />
  </div>
</template>
<script>
import BottomNavigation from '@/components/BottomNavigation.vue'
export default {
  components: { BottomNavigation }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
