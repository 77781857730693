export default (to, from, next) => {
  let auth = localStorage.getItem('auth')
  if (auth) {
    auth = JSON.parse(auth)
    if (auth.user && auth.refresh_token) {
      next()
    } else {
      localStorage.removeItem('auth')
      localStorage.removeItem('jwt')
      next('/login')
    }
  } else {
    next('/login')
  }
}
