import { render, staticRenderFns } from "./AddItemListView.vue?vue&type=template&id=7cb0b4e0&scoped=true&"
import script from "./AddItemListView.vue?vue&type=script&lang=js&"
export * from "./AddItemListView.vue?vue&type=script&lang=js&"
import style0 from "./AddItemListView.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AddItemListView.vue?vue&type=style&index=1&id=7cb0b4e0&lang=scss&scoped=true&"
import style2 from "./AddItemListView.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cb0b4e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VMain,VTextField})
