<template>
  <app-bar hideBar class="mb-5">
    <v-card flat class="mx-auto fill-height white lighten-5 mb-15">
      <v-dialog
        v-model="dialog"
        width="500"
      >

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{dialogContent.name}}
          </v-card-title>

          <v-card-text class="py-5">
            <div
              :key="formattedKey"
              v-for="formattedKey in Object.keys(dialogContent.formatted_data)"
            >
              <v-container>
                <v-row justify="center" align="center">
                  <v-col cols="10">
                    <span>
                      <strong style="text-transform: capitalize;">{{$t(formattedKey)}}</strong>:
                      {{dialogContent.formatted_data[formattedKey]}}
                    </span>
                  </v-col>
                  <v-col
                    cols="2"
                    class="text-end"
                    v-clipboard:copy="dialogContent.formatted_data[formattedKey]"
                    v-clipboard:success="onSuccess"
                    v-clipboard:error="onError"
                  >
                    <span>
                      <v-icon>mdi-clipboard-text-multiple-outline</v-icon>
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <loader :loading="loading">
        <template v-if="user">
          <profile-header
            :avatar="user.picture_url"
            :name="user.name"
            :title="user.title"
            :description="user.description"
            :fields="fields"
          />
          <v-divider class="mx-4 my-4"></v-divider>
          <v-container>
            <v-row flex justify="center" class="mx-5 mt-2" align="center">
              <template v-for="field in fields">
                <v-col
                  v-if="field.format && typeof field.format == 'object'"
                  :key="field.name + field.data"
                  :cols="4"
                  align-self="start"
                  class="px-3"
                  style="flex: 1"
                >
                  <span
                    @click="openDialog(field)"
                    :alt="field.name"
                    style="all: unset"
                  >
                    <v-layout column justify-center align-center>
                      <v-img
                        :src="field.icon_url"
                        style="border-radius: 20px !important"
                        max-width="20vw"
                        max-height="20vw"
                        class="item-icon"
                        v-ripple
                      ></v-img>
                      <p
                        class="text-overline text-center mt-3"
                        style="line-height: 1rem; font-size: 0.55rem !important; text-transform: none !important"
                      >
                        {{ field.name }}
                      </p>
                    </v-layout>
                  </span>
                </v-col>
                <v-col
                  v-else
                  :key="field.name + field.data"
                  :cols="4"
                  align-self="start"
                  class="px-3"
                  style="flex: 1"
                >
                  <a
                    :href="field.data"
                    target="_blank"
                    :alt="field.name"
                    style="all: unset"
                  >
                    <v-layout column justify-center align-center>
                      <v-img
                        :src="field.icon_url"
                        max-width="20vw"
                        max-height="20vw"
                        style="border-radius: 20px !important"
                        class="item-icon"
                        v-ripple
                      ></v-img>
                      <p
                        class="text-overline text-center mt-3"
                        style="line-height: 1rem; font-size: 0.55rem !important; text-transform: none !important"
                      >
                        {{ field.name }}
                      </p>
                    </v-layout>
                  </a>
                </v-col>
              </template>
            </v-row>
              <div class="d-flex justify-center mt-14">
                <p @click="disclaimerModal = !disclaimerModal" class="text-caption">Sorumluluk reddi için <b>tıklayınız.</b></p>
              </div>
              <v-dialog
                v-model="disclaimerModal"
                width="600"
              >
                <v-card>
                  <div class="px-5 py-5">
                    <h3 class="text-center mb-5">Sorumluluk Reddi</h3>
                    <p>
                      Burada yer alan bilgiler kullanıcılarımız tarafından serbestçe belirlenmekte olup,
                      bu bilgilerin doğruluğundan veya bu bilgiler nedeniyle uğrayabileceğiniz zararlardan
                      IDY Card sorumlu değildir.
                    </p>
                    <p>
                      IDY Card'ın müşterisi olmasanız dahi sistemlerimizi kullanarak
                      kişisel verilere ilişkin aydınlatma metnimizi ve gizlilik politikamızı
                      kabul etmiş sayılırsınız.
                    </p>
                  </div>
                </v-card>
              </v-dialog>
              <v-snackbar
                v-model="snackbarData.snackbar"
                :timeout="snackbarData.timeout"
                :color="snackbarData.color"
                elevation="24"
              >
                {{ snackbarData.text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    icon
                    v-bind="attrs"
                    @click="snackbarData.snackbar = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
          </v-container>
        </template>
      </loader>
    </v-card>
  </app-bar>
</template>

<script>
import ProfileHeader from '@/components/ProfileHeader.vue'
import Loader from '@/components/Loader.vue'
import axios from '@/utils/axios'
import AppBar from '@/components/AppBar.vue'

export default {
  components: { ProfileHeader, Loader, AppBar },
  data: function () {
    return {
      loading: false,
      disclaimerModal: false,
      user: null,
      fields: [],
      dialog: false,
      dialogContent: {
        name: '',
        formatted_data: {}
      },
      snackbarData: {
        snackbar: false,
        text: '',
        color: 'green',
        timeout: 3000
      }
    }
  },
  mounted () {
    console.log('MOUNTED')
    const publicKey = this.$route.params.cardId
    const privateKey = this.$route.params.cardSecret
    // console.log('@@@@@@', publicKey, privateKey)
    this.loading = true
    new Promise((resolve, reject) => {
      axios
        .get(`/card/${publicKey}`)
        .then(async (res) => {
          if (res?.data?.data) {
            const data = res.data.data

            if (data.user_id === null) {
              return this.$router.replace({
                name: 'Register',
                params: { public_key: publicKey, private_key: privateKey }
              })
            } else {
              if (data.direct_mode_field_id) {
                const directField = data.fields.find(
                  (i) => i.id === data.direct_mode_field_id
                )
                window.location = `${directField.field_type.prefix}${directField.data}${directField.field_type.postfix}`
              } else {
                // GET FIELDS
                await axios
                  .get(`/card/${publicKey}/profile`)
                  .then((profileResponse) => {
                    if (profileResponse?.data?.data) {
                      this.user = profileResponse.data.data.user
                      this.fields = profileResponse.data.data.card
                    }
                  })
              }
            }
          }
          reject(new Error())
        })
        .catch((err) => {
          reject(err)
        })
    })
      .catch((err) => {
        if (err?.response?.status === 404) {
          this.$router.replace({ name: '404' })
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    openDialog (field) {
      this.dialogContent.name = field.name
      this.dialogContent.formatted_data = field.formatted_data
      this.dialog = true
    },
    onSuccess (e) {
      this.snackbarData.text = 'Kopyalandı!'
      this.snackbarData.color = 'green'
      this.snackbarData.snackbar = true
    },
    onError (e) {
      this.snackbarData.text = 'Kopyalanırken bir hata oluştu!'
      this.snackbarData.color = 'red accent-2'
      this.snackbarData.snackbar = true
    }
  }
}
</script>

<style lang="scss">
.item-icon {
  @media (min-width: 360px) {
    max-width: 75px !important;
    maxheight: 75px !important;
  }
}
</style>
