<template>
  <app-bar title="Profile" :shareObject="shareObject" :previewLink="previewLink">
    <page class="mt-5">
      <loader :loading="loading">
        <!-- <profile-header
          v-if="user"
          editable
          :avatar="user.picture_url"
          :name="user.name"
          :title="user.title"
          :description="user.description"
        /> -->
        <v-card v-if="cardProfile" flat class="pt-2" style="width: 100%;">
          <div class="d-flex flex-row align-center" style="width: 100%;">
            <v-avatar absolute bottom fab size="5rem" align="center">
              <v-img v-if="cardProfile.picture_url" :src="cardProfile.picture_url"></v-img>
              <v-img v-else src="@/assets/profile.webp"></v-img>
            </v-avatar>
            <div class="d-flex flex-column align-start">
              <v-card-title v-if="cardProfile.name" class="d-flex justify-center align-center pt-0 pb-3" style="font-size: 1.1rem">
                {{cardProfile.name}}
              </v-card-title>
              <v-card-subtitle v-if="cardProfile.title" class="py-0" align="center" style="font-size: 0.8rem">
                {{cardProfile.title}}
              </v-card-subtitle>

              <v-card-subtitle v-if="cardProfile.description" class="py-0" align="center" style="font-size: 0.8rem">
                {{cardProfile.description}}
              </v-card-subtitle>
            </div>
          </div>
          <v-col class="px-0 py-0 mt-6" style="width: 100%;">
            <div
              class="d-flex justify-center align-center rounded-xl"
              style="border: 2px solid lightGray;
              flex: 1;
              height: 3rem;
              font-size: 1.2rem;"
              @click="$router.push({name: 'Edit Card Profile', params: { cardId: card.public_key }})"
            >
              {{ $t('editProfile') }}
            </div>
          </v-col>
        </v-card>
        <v-card v-else-if="cardProfile === null && user" flat class="pt-2" style="width: 100%;">
          <div class="d-flex flex-row align-center" style="width: 100%;">
            <v-avatar absolute bottom fab size="5rem" align="center">
              <v-img v-if="user.picture_url" :src="user.picture_url"></v-img>
              <v-img v-else src="@/assets/profile.webp"></v-img>
            </v-avatar>
            <div class="d-flex flex-column align-start">
              <v-card-title v-if="user.name" class="d-flex justify-center align-center pt-0 pb-3" style="font-size: 1.1rem">
                {{user.name}}
              </v-card-title>
              <v-card-subtitle v-if="user.title" class="py-0" align="center" style="font-size: 0.8rem">
                {{user.title}}
              </v-card-subtitle>

              <v-card-subtitle v-if="user.description" class="py-0" align="center" style="font-size: 0.8rem">
                {{user.description}}
              </v-card-subtitle>
            </div>
          </div>
          <v-col class="px-0 py-0 mt-6" style="width: 100%;">
            <div
              class="d-flex justify-center align-center rounded-xl"
              style="border: 2px solid lightGray;
              flex: 1;
              height: 3rem;
              font-size: 1.2rem;"
              @click="$router.push({name: 'Edit Profile'})"
            >
              {{ $t('editProfile') }}
            </div>
          </v-col>
        </v-card>
        <v-slide-group
          v-if="multiCard"
          class="py-4 pb-0 mt-3"
          active-class="grey lighten-3"
          show-arrows
          v-model="selectedCardIndex"
          @change="selectedHandler"
          mandatory
        >
          <v-slide-item
            v-for="c in cardList"
            :key="c.id"
            v-slot="{ toggle }"
          >
            <v-card
              flat
              :ripple="false"
              class="d-flex flex-column justify-center align-center ma-0"
              height="100"
              width="100"
              @click="toggle"
            >
              <v-row
                class="fill-height pt-1"
                align="center"
                justify="center"
              >
                <v-scale-transition>
                  <img :src="c.icon" style="width: 100px; height: 100px; object-fit: contain;" />
                </v-scale-transition>
              </v-row>
              <span class="pb-2">
                {{c.user_preferred_name ? c.user_preferred_name : c.public_key}}
              </span>
            </v-card>
          </v-slide-item>
        </v-slide-group>
        <loader :loading="cardLoading">
          <v-col v-if="card" class="px-0">
            <v-row v-if="fields.length" class="align-center justify-end">
              <div class="subtitle-2 mx-3">{{ $t('direct') }}</div>
              <v-switch
                v-model="isDirect"
                inset
                :ripple="false"
                color="black"
                @change="directModeOnChange"
              ></v-switch>
            </v-row>
            <template v-if="card && reRenderControl">
              <list-group
                v-model="fields"
                :card-id="this.card.public_key"
                :isDirect="isDirect"
              />
            </template>
            <div style="width: 100%; height: 10rem; background-color: black; border-radius: 1rem;">
              <v-col class="d-flex flex-column align-center" style="height: 100%;">
                <v-row class="d-flex justify-center align-center">
                  <v-col cols="2" class="d-flex justify-center align-center">
                    <v-row class="d-flex justify-end align-end">
                      <img src="@/assets/addContent.svg" style="width: 2rem; object-fit: contain;" />
                    </v-row>
                  </v-col>
                  <v-col cols="10" class="d-flex justify-center align-center">
                    <v-row class="d-flex justify-center align-center my-1 mx-1 pr-2">
                      <span style="color: white; text-align: center;">{{ $t("addNewItemInfo") }}</span>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center align-center px-2 mb-0" style="width: 100%;">
                  <v-flex
                    class="rounded-lg blue-grey lighten-5"
                    @click="$router.push({ name: 'Add Item List', params: { cardId: card.public_key } })"
                  >
                    <v-list-item value="content.name">
                      <v-list-item-content style="">
                        <v-list-item-title class="d-flex justify-center align-center">{{
                          $t("addNewItem")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-flex>
                </v-row>
              </v-col>
            </div>
          </v-col>
        </loader>
      </loader>
    </page>
  </app-bar>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
// import ProfileHeader from '@/components/ProfileHeader.vue'
import ListGroup from '@/components/ListGroup.vue'
import axios from '@/utils/axios'
import Loader from '@/components/Loader.vue'
import Page from '@/components/Page.vue'

const tagBlack = require('@/assets/tagBlack.png')
const tagWhite = require('@/assets/tagWhite.png')
const tagMultiColor = require('@/assets/tagMultiColor.png')
const cardBlack = require('@/assets/cardBlack.png')
const cardWhite = require('@/assets/cardWhite.png')
const cardMultiColor = require('@/assets/cardMultiColor.png')

export default {
  components: {
    AppBar,
    // ProfileHeader,
    ListGroup,
    Loader,
    Page
  },
  data () {
    return {
      loading: false,
      user: null,
      // CARD VARIABLES
      cardProfile: null,
      multiCard: false,
      selectedCardIndex: null,
      cardList: [],
      isDirect: false,
      directModeFieldId: null,
      cardLoading: false,
      card: null,
      fields: [],
      reRenderControl: true
    }
  },
  computed: {
    shareObject () {
      if (this.card?.public_key) {
        return { title: this.user.name, url: `https://id.idycard.com/${this.card.public_key}` }
      }
      return false
    },
    previewLink () {
      if (this.card?.public_key) {
        return `https://id.idycard.com/${this.card.public_key}`
      }
      return false
    }
  },
  mounted () {
    this.getProfile()
    this.getCardList()
      .then((cardList) => {
        if (cardList.length === 1) {
          this.getCard(cardList[0].public_key)
        } else if (cardList.length > 1) {
          this.multiCard = true
        }
      })
  },
  methods: {
    getProfile () {
      this.loading = true
      const userId = this.$store?.state?.auth?.login?.user?.id
      axios.get(`/user/${userId}`).then((res) => {
        if (res?.data?.data) {
          this.user = res.data.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getCardProfile () {
      this.loading = true
      const cardId = this.card.public_key
      axios.get(`/user/card/profile/${cardId}`).then((res) => {
        if (res?.data?.data) {
          this.cardProfile = res.data.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getCardType (id) {
      switch (id) {
        case 1: return 'card'
        case 2: return 'tag'
        default: return null
      }
    },
    getCardList () {
      return new Promise((resolve, reject) => {
        axios.get('/user/card').then((res) => {
          if (res?.data?.data) {
            this.cardList = res.data.data.map((item) => {
              const type = this.getCardType(item.card_type_id)
              let icon = null

              if (type === 'card') {
                icon = item.color_id === 1
                  ? cardBlack
                  : item.color_id === 2
                    ? cardWhite
                    : cardMultiColor
              } else if (type === 'tag') {
                icon = item.color_id === 1
                  ? tagBlack
                  : item.color_id === 2
                    ? tagWhite
                    : tagMultiColor
              }

              return {
                id: item?.id,
                type,
                icon,
                name: item.public_key,
                public_key: item.public_key,
                user_preferred_name: item.user_preferred_name
              }
            })
            resolve(this.cardList)
          }
        })
      })
    },
    getCard (cardId) {
      this.cardLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/card/${cardId}`).then((res) => {
          this.fields = null
          this.card = null
          if (res?.data?.data) {
            this.card = res.data.data

            if (this.card.direct_mode_field_id) {
              this.isDirect = true
              this.directModeFieldId = this.card.direct_mode_field_id
            } else {
              this.isDirect = false
            }

            if (res.data.data.fields) {
              this.fields = res.data.data.fields.map((item) => {
                let isActive = false
                if (this.isDirect) {
                  isActive = this.directModeFieldId === item?.id
                } else {
                  isActive = item.is_active
                }

                return {
                  id: item?.id,
                  name: item.name,
                  isActive,
                  icon: item.custom_icon_url ? item.custom_icon_url : item.field_type.icon_url,
                  cardId: cardId,
                  haveMultipleFields: item.formatted_data && Object.keys(item.formatted_data).length > 0
                }
              })
              this.reRenderControl = false
              this.$nextTick(() => {
                this.reRenderControl = true
              })
              resolve(res.data.data)
            } else {
              this.fields = []
              resolve(res.data.data)
            }
          }
        }).finally(() => {
          this.$forceUpdate()
          this.cardLoading = false
        })
      })
    },
    directModeOnChange () {
      if (!this.isDirect) {
        return axios.post(`/card/${this.card.public_key}/direct_mode`, {
          card_field_id: null
        }).then(() => {
          this.getCard(this.card.public_key)
        }).catch(() => {
          this.isDirect = true
        })
      }
    },
    selectedHandler () {
      this.card = this.cardList[this.selectedCardIndex]
      this.getCard(this.card.public_key)
      this.getCardProfile()
    }
  }
}
</script>
