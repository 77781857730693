<template>
  <app-bar title="Settings">
    <v-main>
      <v-col class="px-0 py-0 mx-0 my-0">
        <v-carousel class="d-flex justify-center align-center" v-model="selectedItemIndex" hide-delimiters
          hide-delimiter-background :continuous="false" show-arrows height="200">
          <v-carousel-item v-for="(item, i) in items" :key="item.id" :value="i" :src="item.icon" cover max-height="auto"
            max-width="300">
          </v-carousel-item>
        </v-carousel>
        <div class="d-flex justify-space-around align-center py-0">
          <span class="lighten-5 text-h5">{{ selectedItem ? selectedItem.name : '' }}</span>
        </div>
      </v-col>

      <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;">
        <v-row>
          <v-col cols="6" class="py-0 pr-5">
            <div @click="editCardDialog = true" class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row my-5"
              style="height: 120px; justify-content: space-between; align-items: center;">
              <v-row>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-icon size="40">mdi-file-edit-outline</v-icon>
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center">
                  {{ $t('editCard') }}
                </v-col>
              </v-row>
            </div>
            <div @click="qrCodeDialog = true" class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row my-5"
              style="height: 120px; justify-content: space-between; align-items: center;">
              <v-row>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-icon size="40">mdi-qrcode-scan</v-icon>
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center">
                  {{ $t('qrCode') }}
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="6" class="py-0 pl-5">
            <div @click="deleteCardDialog = true" class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row my-5"
              style="height: 120px; justify-content: space-between; align-items: center;">
              <v-row>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-icon size="40" class="trash-icon-color">mdi-trash-can-outline</v-icon>
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <span class="trash-font-color">{{ $t('deleteCard') }}</span>
                </v-col>
              </v-row>
            </div>
            <div @click="shareHandler" class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row my-5"
              style="height: 120px; justify-content: space-between; align-items: center;">
              <v-row>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <BrandTelegram style="width: 40px; height: 40px; color: #0000008a" />
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center">
                  {{ $t('share') }}
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12">
            <div
              class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row"
              style="height: 60px; justify-content: center; align-items: center;"
            >
              <div class="d-flex justify-center align-center">
                {{$t("addNewCard")}}
              </div>
            </div>
          </v-col>
        </v-row> -->
      </v-col>

      <!-- <v-col cols="12" md="12" class="px-0">
          <template v-for="(item, index) in items">
            <list-item
              :key="item.id"
              class="my-2"
              v-model="items[index]"
              :click="() => $router.push({name: 'Card', params: {cardId: item.public_key } })"
              hideSwitch
              disableDraggable
            />
          </template>
</v-col> -->

      <v-dialog
        v-model="editCardDialog"
        width="400"
      >
      <div class="rounded-lg blue-grey lighten-5">
        <div class="d-flex justify-center align-center mb-5 mx-5 pt-5 px-5">
          <span class="lighten-5 text-h5">{{ $t('editCardTitle') }}</span>
        </div>
        <div class="d-flex justify-center align-center mx-5 pt-5 px-5">
          <span class="lighten-5 text-subtitle-2">{{ $t('editCardMessage') }}</span>
        </div>
        <div class="d-flex justify-center align-center mx-5 pt-5 px-5">
          <v-text-field filled dense rounded class="rounded-lg" height="4rem" :label="$t('editCardInputPlaceholder')" v-model="editCardNameInput"></v-text-field>
        </div>
        <div class="d-flex justify-center align-center pb-2 px-5">
          <v-btn @click="saveCardName" class="rounded-lg white--text" color="green" size="xl" block height="50">{{ $t('save') }}</v-btn>
        </div>
        <div class="d-flex justify-center align-center pb-5 pt-2 px-5">
          <v-btn class="rounded-lg white--text" color="#424242" size="xl" block height="50" @click="editCardDialog = false">{{ $t('cancel') }}</v-btn>
        </div>
      </div>
      </v-dialog>

      <v-dialog
        v-model="qrCodeDialog"
        width="400"
      >
      <div class="rounded-lg blue-grey lighten-5">
        <div class="d-flex justify-center align-center mb-5 mx-5 pt-5 px-5">
          <span class="lighten-5 text-h5">{{ selectedItem ? selectedItem.name : "" }}</span>
        </div>
        <div class="d-flex justify-center align-center mb-5 mx-5 pt-5 px-5">
          <qrcode-vue :value="`https://id.idycard.com/${this.selectedItem ? this.selectedItem.public_key : ''}`" :size="300" level="H" />
        </div>
        <div class="d-flex justify-center align-center py-5 px-5">
          <v-btn class="rounded-lg white--text" color="#424242" size="xl" block height="50" @click="qrCodeDialog = false">{{ $t('close') }}</v-btn>
        </div>
      </div>
      </v-dialog>

      <v-dialog
        v-model="deleteCardDialog"
        width="400"
      >
      <div class="rounded-lg blue-grey lighten-5">
        <div class="d-flex justify-center align-center mb-5 mx-5 pt-5 px-5">
          <span class="lighten-5 text-h5">{{ $t('deleteCardTitle') }}</span>
        </div>
        <div class="d-flex justify-center align-center mx-5 pt-0 px-5">
          <span class="lighten-5 text-subtitle-2">{{ $t('deleteCardMessage') }}</span>
        </div>
        <div class="d-flex justify-center align-center pb-2 pt-5 px-5">
          <v-btn @click="deleteCard" class="rounded-lg white--text" color="red" size="xl" block height="50">{{ $t('delete') }}</v-btn>
        </div>
        <div class="d-flex justify-center align-center py-5 px-5">
          <v-btn class="rounded-lg white--text" color="#424242" size="xl" block height="50" @click="deleteCardDialog = false">{{ $t('close') }}</v-btn>
        </div>
      </div>
      </v-dialog>
    </v-main>
  </app-bar>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import axios from '@/utils/axios'
// import ListItem from '@/components/ListItem.vue'
import {
  BrandTelegram
} from 'vue-icon-packs/ti'
import QrcodeVue from 'qrcode.vue'

const tagBlack = require('@/assets/tagBlack.png')
const tagWhite = require('@/assets/tagWhite.png')
const tagMultiColor = require('@/assets/tagMultiColor.png')
const cardBlack = require('@/assets/cardBlack.png')
const cardWhite = require('@/assets/cardWhite.png')
const cardMultiColor = require('@/assets/cardMultiColor.png')

export default {
  components: {
    AppBar,
    BrandTelegram,
    QrcodeVue
    // ListItem
  },
  data () {
    return {
      items: [],
      selectedItemIndex: null,
      selectedItem: null,
      qrCodeDialog: false,
      qrCodeValue: null,
      editCardDialog: false,
      editCardNameInput: null,
      deleteCardDialog: false
    }
  },
  computed: {
    shareObject () {
      if (this.selectedItem?.public_key) {
        return { title: this.$store.state.auth.login.user.name, url: `https://id.idycard.com/${this.selectedItem.public_key}` }
      }
      return false
    }
  },
  mounted () {
    this.getCards()
  },
  methods: {
    getCards () {
      return new Promise((resolve, reject) => {
        axios.get('/user/card').then((res) => {
          if (res?.data?.data) {
            this.items = res.data.data.map((item) => {
              const type = this.getCardType(item.card_type_id)
              let icon = null

              if (type === 'card') {
                icon = item.color_id === 1
                  ? cardBlack
                  : item.color_id === 2
                    ? cardWhite
                    : cardMultiColor
              } else if (type === 'tag') {
                icon = item.color_id === 1
                  ? tagBlack
                  : item.color_id === 2
                    ? tagWhite
                    : tagMultiColor
              }

              return {
                id: item.id,
                type,
                icon,
                name: item.user_preferred_name ? item.user_preferred_name : item.public_key,
                public_key: item.public_key
              }
            })

            /// TODO: Mock data
            // const card1 = { ...this.items[0] }
            // const card2 = { ...this.items[0] }
            // const card3 = { ...this.items[0] }

            // const item1 = card1
            // item1.icon = cardWhite
            // item1.name = 'Card 1'
            // item1.id = 1503
            // item1.public_key = 'public_key_1'

            // const item2 = card2
            // item2.icon = cardBlack
            // item2.name = 'Card 2'
            // item2.id = 1504
            // item2.public_key = 'public_key_2'

            // const item3 = card3
            // item3.icon = tagMultiColor
            // item3.name = 'Tag 1'
            // item3.id = 1505
            // item3.public_key = 'public_key_3'

            // const items = [item1, item2, item3]

            // this.items = items
            /// TODO End mock data

            const activeCardId = this.$store.state.cards.cards.activeCardId
            if (activeCardId) {
              this.selectedItemIndex = this.items.findIndex((item) => item.id === parseInt(activeCardId))
              this.selectedItem = this.items.find((item) => item.id === activeCardId)
              this.editCardNameInput = this.selectedItem?.name
            } else {
              this.selectedItemIndex = 0
              this.selectedItem = this.items[0]
              this.editCardNameInput = this.selectedItem?.name
            }

            resolve(res.data.data)
          }
        })
      })
    },
    getCardType (id) {
      switch (id) {
        case 1: return 'card'
        case 2: return 'tag'
        default: return null
      }
    },
    shareHandler () {
      if (navigator.share) {
        navigator.share(this.shareObject)
      }
    },
    saveCardName () {
      axios.put(`/card/${this.selectedItem.public_key}/name`, {
        name: this.editCardNameInput
      }).then((res) => {
        this.selectedItem.name = this.editCardNameInput
        this.getCards()
        this.editCardNameInput = ''
        this.editCardDialog = false
      }).catch((err) => {
        console.log(err)
      })
    },
    deleteCard () {
      axios.delete(`/card/${this.selectedItem.public_key}`).then((res) => {
        this.getCards()
        this.deleteCardDialog = false
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  watch: {
    selectedItemIndex (newValue) {
      const item = this.items.find((item, index) => index === newValue)
      this.selectedItem = item
      this.editCardNameInput = this.selectedItem?.name
      this.$store.dispatch('cards/set_active_card_id', item.id)
    }
  }
}
</script>

<style scoped>
.trash-icon-color {
  color: #E53935;
}
.trash-font-color {
  color: #E53935;
}
</style>
