import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'

import { login, register, forgotPassword, resetPassword } from './modules/auth'
import { cards } from './modules/cards'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: {
      namespaced: true,
      modules: {
        login,
        register,
        forgotPassword,
        resetPassword
      }
    },
    cards: {
      namespaced: true,
      modules: {
        cards
      }
    }
  },
  state: {
    profile: {
      name: 'Mustafa ÇELİKEL',
      location: 'İstanbul',
      description: 'Ceo of Mach Co.'
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField
  },
  actions: {
  }
})
