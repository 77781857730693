<template>
  <app-bar title="Settings">
    <page class="mt-5">
      <loader :loading="loading">
        <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;">
          <p class="d-flex justify-center align-center" style="font-size: 1.5rem;">{{ $t('settings') }}</p>
        </v-col>
        <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;"
          @click="$router.push({name: 'Edit Profile'})"
        >
          <div
            class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row"
            style="height: 60px; justify-content: space-between; align-items: center;"
          >
            <div class="d-flex justify-center align-center">
              {{$t('editProfile')}}
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;"
          @click="$router.push({name: 'Change Password'})"
        >
          <div
            class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row"
            style="height: 60px; justify-content: space-between; align-items: center;"
          >
            <div class="d-flex justify-center align-center">
              {{$t('changePassword')}}
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;">
          <div
            class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row"
            style="height: 60px; justify-content: space-between; align-items: center;"
          >
            <div class="d-flex justify-center align-center">
              {{ $t('hideMyProfile') }}
            </div>
              <v-switch
                v-if="!loading"
                v-model="hideProfile"
                color="black"
                inset
                dense
                :ripple="false"
                @change="hideProfileOnChange"
              ></v-switch>
          </div>
        </v-col>

        <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;"
          @click="dialog = true"
        >
          <div
            class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row"
            style="height: 60px; justify-content: space-between; align-items: center;"
          >
            <div class="d-flex justify-center align-center font-weight-bold">
              {{$t('deleteAccount')}}
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="px-5 py-0 mt-5" style="width: 100%;"
          @click="$router.push({ name: 'Logout' })"
        >
          <div
            class="rounded-lg blue-grey lighten-5 py-1 px-5 d-flex flex-row"
            style="height: 60px; justify-content: center; align-items: center;"
          >
            <div class="d-flex justify-center align-center font-weight-bold">
              {{$t("logout")}}
            </div>
          </div>
        </v-col>

        <v-dialog
          v-model="dialog"
          persistent
          max-width="490"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{$t('deleteAccountTitle')}}
            </v-card-title>

            <v-card-text class="py-5">

                <v-container>
                  <v-row justify="center" align="center">
                    <v-col cols="10">
                      <span>
                        <strong style="text-transform: capitalize;"> {{$t('deleteAccountMessage')}}</strong>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="dialog = false">
                {{$t('cancel')}}
              </v-btn>

              <v-btn @click="deleteAccount" class="d-flex justify-center align-center font-weight-bold">
                {{$t('deleteAccount')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </loader>
    </page>
  </app-bar>
</template>

<script>
import axios from '@/utils/axios'
import AppBar from '@/components/AppBar.vue'
import Loader from '@/components/Loader.vue'
import Page from '@/components/Page.vue'

export default {
  components: {
    AppBar,
    Page,
    Loader
  },
  data () {
    return {
      hideProfile: false,
      loading: true,
      dialog: false
    }
  },
  mounted () {
    this.getHideProfile()
  },
  methods: {
    getHideProfile () {
      this.loading = true
      const userId = this.$store.state.auth.login.user.id
      axios.get(`/user/${userId}`).then((res) => {
        if (res?.data?.data) {
          this.hideProfile = res.data.data.is_hidden
        }
      }).finally(() => {
        this.loading = false
      })
    },
    hideProfileOnChange (value) {
      axios.put('/user', {
        is_hidden: value
      }).catch(() => {
        this.hideProfile = !value
      })
    },
    deleteAccount () {
      this.loading = true
      const userId = this.$store.state.auth.login.user.id
      axios.delete(`/user/${userId}`).then((res) => {
        this.loading = false
        this.$router.push({ name: 'Logout' })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
