import axios from './axios'
import store from '@/store/index.js'

export default function setup () {
  axios.interceptors.request.use(function (config) {
    window.store = store
    const jwt = store.getters['auth/jwt']
    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }
    return config
  }, function (err) {
    return Promise.reject(err)
  })

  axios.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    const originalRequest = error.config

    if (status === 403) {
      const userInfo = localStorage.getItem('auth')
      if (!userInfo) {
        window.location = '/logout'
        return
      }
    }

    if (status === 401 || status === 403) {
      return store.dispatch('auth/refreshJwt').then((res) => {
        if (res?.data?.token) {
          originalRequest.headers.Authorization = 'Bearer ' + res.jwt

          return axios.request(originalRequest)
        }
      }).catch(() => {
        window.location = '/logout'
      })
    }

    return Promise.reject(error)
  })
}
