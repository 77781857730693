<template>
  <app-bar :title="$t('editProfile')" backButton>
    <v-main class="edit-profile-view">
      <loader :loading="loading">
        <v-col v-if="user" class="px-0 mb-10">
          <v-card flat>
            <v-col class="px-0">
              <v-row justify="center">
                <v-btn @click="handleFileImport()" absolute bottom fab ripple="">
                  <v-avatar absolute bottom fab size="10rem" align="center">
                    <v-img v-if="croppedFileBase64" :src="croppedFileBase64"></v-img>
                    <v-img v-else-if="user.picture_url" :src="user.picture_url"></v-img>
                    <v-img v-else src="@/assets/profile.webp"></v-img>
                    <v-overlay absolute>
                      <v-icon>mdi-cloud-upload-outline</v-icon>
                    </v-overlay>
                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        @change="onFileChanged"
                        accept="image/*;capture=camera"
                    >
                  </v-avatar>
                </v-btn>
              </v-row>
              <v-img
                max-height="10rem"
                src="@/assets/forest.webp"
              >
                <v-container class="fill-height">
                  <v-row align="center">
                    <v-row justify="center">
                    </v-row>
                  </v-row>
                </v-container>
              </v-img>
            </v-col>
          </v-card>
          <v-spacer style="margin-top: 6rem;"></v-spacer>
            <v-col class="px-5">
              <v-text-field filled dense rounded class="rounded-lg" height="4rem" :label="$t('profileName')" v-model="user.name"></v-text-field>
              <v-text-field filled dense rounded class="rounded-lg" height="4rem" :label="$t('location_or_title')" v-model="user.title"></v-text-field>
              <v-textarea filled dense rounded class="rounded-lg" :label="$t('description')" v-model="user.description"></v-textarea>
              <div v-if="errorCode" class="subtitle-2 red--text pb-4 px-2">
                {{$t(`errorCodes.${[errorCode]}`)}}
              </div>
              <v-btn @click="updateProfile" :loading="buttonLoading" block color="#2f3542" depressed class="white--text rounded-lg py-6 mb-5">{{$t('save')}}</v-btn>
            </v-col>
        </v-col>
      </loader>
    </v-main>
    <v-dialog
      v-model="cropperDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('cropImage')}}
        </v-card-title>
        <picture-cropper
          :img="selectedProfilePicture"
          @croppedFile="handleCroppedFile"
          :cancelButtonAction="closeDialog"
          rounded
        />
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </app-bar>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Loader from '@/components/Loader.vue'
import axios from '@/utils/axios'
import PictureCropper from '@/components/PictureCropper.vue'
import heic2any from 'heic2any'
import { compress } from 'image-conversion'

export default {
  components: {
    AppBar,
    Loader,
    PictureCropper
  },
  data () {
    return {
      cardId: this.$route.params.cardId,
      loading: false,
      buttonLoading: false,
      isSelectingProfileImage: false,
      selectedProfilePicture: '',
      user: null,
      cropperDialog: false,
      croppedFile: null,
      croppedFileBase64: null,
      errorCode: null
    }
  },
  mounted () {
    this.getProfile()
  },
  methods: {
    getProfile () {
      if (this.cardId) {
        this.loading = true
        axios.get(`/user/card/profile/${this.cardId}`).then((res) => {
          if (res?.data?.data) {
            this.user = res.data.data
          }
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.loading = true
        const userId = this.$store.state.auth.login.user.id
        axios.get(`/user/${userId}`).then((res) => {
          if (res?.data?.data) {
            this.user = res.data.data
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    async updateProfile () {
      const formData = new FormData()

      formData.append('name', this.user.name)
      formData.append('title', this.user.title)
      formData.append('description', this.user.description)
      if (this.croppedFile) {
        const compressedFile = await compress(this.croppedFile, {
          quality: 0.8,
          width: 720,
          height: 720,
          type: 'image/jpeg'
        })

        formData.append('profile_picture', compressedFile)
      }

      /*
      axios.put('/user', formData).then((res) => {
        this.$router.push({ name: 'Profile' })
        this.$router.go()
      }).catch((err) => {
        if (err?.response?.data?.code) {
          this.errorCode = err.response.data.code
        } else {
          this.errorCode = 1
        }
      }).finally(() => {
        this.buttonLoading = false
      })
      */

      this.buttonLoading = true
      console.log('formData', formData)
      if (this.cardId) {
        axios.put(`/user/card/profile/${this.cardId}`, formData).then(() => {
          this.$router.push({ name: 'Profile' })
          this.$router.go()
        }).catch((err) => {
          if (err?.response?.data?.code) {
            this.errorCode = err.response.data.code
          } else {
            this.errorCode = 1
          }
        }).finally(() => {
          this.buttonLoading = false
        })
      } else {
        console.log('formData', formData)
        axios.put('/user', formData).then((res) => {
          this.$router.push({ name: 'Profile' })
          this.$router.go()
        }).catch((err) => {
          if (err?.response?.data?.code) {
            this.errorCode = err.response.data.code
          } else {
            this.errorCode = 1
          }
        }).finally(() => {
          this.buttonLoading = false
        })
      }
    },
    handleFileImport () {
      this.isSelectingProfileImage = true

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
        this.isSelectingProfileImage = false
      }, { once: true })
      // Trigger click on the FileInput
      this.$refs.uploader.click()
    },
    async onFileChanged (e) {
      let img = e.target.files[0]
      if (img.type === 'image/heic') {
        const blobURL = URL.createObjectURL(img)
        const blobRes = await fetch(blobURL)
        const blob = await blobRes.blob()
        img = await heic2any({ blob })
      }

      this.selectedProfilePicture = URL.createObjectURL(img)
      this.cropperDialog = true
    },
    handleCroppedFile ({ file, base64Image }) {
      this.croppedFile = file
      this.croppedFileBase64 = base64Image
      this.closeDialog()
    },
    closeDialog () {
      this.isSelectingProfileImage = false
      this.selectedProfilePicture = ''
      this.$refs.uploader.value = null
      this.cropperDialog = false
    }
  }
}
</script>

<style lang="scss">
.edit-profile-view {
  .v-text-field--filled.v-input--dense .v-label:not(.v-label--active) {
      margin-top: 6px !important;
  }
}

</style>

<style scoped>
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: -16px;
}
</style>
