<template>
  <v-flex class="rounded-lg blue-grey lighten-5 py-1" @click="click">
    <v-list-item :value="content.name" style="padding-left: 10px; padding-right: 10px;">
      <v-icon
        v-if="!disableDraggable"
        color="blue-grey darken-2"
        class="draggable-icon mr-1"
        @click.native="dismissClick"
      >
        mdi-dots-vertical
      </v-icon>
      <div class="d-flex justify-center align-center mr-4 ml-1">
        <img
          height="45px"
          width="45px"
          aspect-ratio="1"
          style="object-fit: contain"
          :src="content.icon"
          :transition="false"
        />
      </div>
      <v-list-item-content>
        <v-list-item-title>{{ content.name }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action v-if="!hideSwitch" @click="dismissClick">
        <v-switch
          v-model="content.isActive"
          color="black"
          inset
          :ripple="false"
          :disabled="disableSwitch"
          @change="setActive"
        ></v-switch>
      </v-list-item-action>
    </v-list-item>
  </v-flex>
</template>

<script>
import axios from '@/utils/axios'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    divider: {
      type: Boolean,
      default: false
    },
    disableDraggable: {
      type: Boolean,
      default: false
    },
    hideSwitch: {
      type: Boolean,
      default: false
    },
    disableSwitch: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function,
      default: () => {}
    },
    isDirect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.content)
    },
    dismissClick (e) {
      e.stopPropagation()
    },
    setActive () {
      const { cardId, id } = this.content
      if (this.isDirect) {
        return axios.post(`/card/${cardId}/direct_mode`, {
          card_field_id: id
        }).catch(() => {
          this.content.isActive = !this.content.isActive
        })
      } else {
        return axios.put(`/card/${cardId}/field/${id}/active`, {
          is_active: this.content.isActive
        }).catch(() => {
          this.content.isActive = !this.content.isActive
        })
      }
    }
  }
}
</script>
