import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './lang/en'
import tr from './lang/tr'

Vue.use(VueI18n)

const messages = {
  en,
  tr
}

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages
})

export default i18n
