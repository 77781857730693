<template>
  <v-card flat>
    <v-app-bar
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      style="z-index: 100;"
      v-if="!hideBar"
    >
      <v-card min-width="3rem" flat v-if="!shareObject">
        <v-icon v-if="backButton" @click="$router.back()">mdi-chevron-left</v-icon>
      </v-card>

      <v-card min-width="4rem" flat v-if="shareObject" >
        <span @click="shareHandler">
          <BrandTelegram style="width: 1.7rem; height: 1.7rem;" />
        </span>
      </v-card>

      <v-spacer></v-spacer>

      <!-- <v-toolbar-title class="d-flex flex-grow-1 justify-center">{{title}}</v-toolbar-title> -->
      <v-toolbar-title class="d-flex flex-grow-1 justify-center">
        <img src="@/assets/logo.svg" style="width: 2.5rem; object-fit: contain;" />
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-card min-width="4rem" flat v-if="previewLink">
        <span @click="previewHandler" class="subtitle-2" style="font-weight: 600">{{ $t('preview') }}</span>
      </v-card>

      <v-card v-if="!previewLink" min-width="3rem" flat>
        <v-btn v-if="searchIcon" icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-card>
    </v-app-bar>
    <v-card flat>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        :class="hideBar ? 'pt-0' : 'pt-14'"
        :style="hideBar ? 'overflow: auto; border-radius: 0;' : ''"
        :max-height="hideBar ? '100vh' : '92vh'"
      >
        <v-container style="padding: 0; padding-bottom: 20px; margin: 0;">
          <slot />
        </v-container>
      </v-sheet>
    </v-card>
  </v-card>
</template>

<script>
import {
  BrandTelegram
} from 'vue-icon-packs/ti'

export default {
  components: {
    BrandTelegram
  },
  props: {
    title: {
      type: String,
      default: 'idy'
    },
    backButton: {
      type: Boolean,
      default: false
    },
    shareObject: {
      type: [Object, Boolean],
      default: false
    },
    searchIcon: {
      type: Boolean,
      default: false
    },
    previewLink: {
      type: [String, Boolean],
      default: false
    },
    hideBar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    shareHandler () {
      if (navigator.share) {
        navigator.share(this.shareObject)
      }
    },
    previewHandler () {
      window.open(this.previewLink, '_blank')
    }
  }
}
</script>

<style>
.v-toolbar__content {
  border-bottom: 1px solid #00000010;
}
</style>

<style scoped>
html::v-deep, body::v-deep {
  overflow-y: scroll !important;
}
</style>
