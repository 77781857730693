<template>
  <div>
    <div class="d-flex flex-column justify-center align-center bg-primary" style="overflow: hidden;">
      <cropper
        class="cropper"
        :src="img"
        :stencil-props="{
          aspectRatio: 1,
        }"
        ref="cropper"

        :stencil-component="rounded ? 'circle-stencil' : 'rectangle-stencil'"
      />
    </div>
    <div class="d-flex justify-center align-center mt-2 mb-1">
      <v-btn class="mr-3" v-if="cancelButtonAction" @click="cancelButtonAction">
        {{$t('cancel')}}
      </v-btn>
      <v-btn color="primary" @click="crop">
        {{$t('crop')}}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  props: {
    img: {
      type: String,
      required: true
    },
    cancelButtonAction: {
      type: [Function, Boolean],
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Cropper
  },
  data: function () {
    return {
    }
  },
  methods: {
    crop () {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      const base64Image = canvas.toDataURL()
      const file = this.dataURLtoFile(base64Image, 'avatar.png')
      console.log(file)
      this.$emit('croppedFile', { file, base64Image })
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    }
  }
}

</script>

<style scoped>
  .cropper {
    background: #DDD;
    max-height: 60vh;
  }
</style>
