<template>
  <v-flex class="rounded-lg blue-grey lighten-5 py-1" @click="click" style="width: 100%; display: flex; align-items: center; height: 70px; max-height: 70px;">
    <v-list-item :value="content.name" style="padding-left: 10px; padding-right: 10px;">
      <div class="d-flex justify-center align-center mr-4 ml-1">
        <img
          height="45px"
          width="45px"
          aspect-ratio="1"
          style="object-fit: contain"
          :src="content.icon_url"
          :transition="false"
        />
      </div>
      <v-list-item-content>
        <v-list-item-title>{{ $t(content.name) }}</v-list-item-title>
      </v-list-item-content>
      <div class="blue-grey lighten-4 px-3 py-1 rounded-pill font-weight-medium">
        {{ $t('add') }}
      </div>
    </v-list-item>
  </v-flex>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },
    divider: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function,
      default: () => {}
    },
    isDirect: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
