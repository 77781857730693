<template>
<v-container fill-height>
  <v-card flat width="100%" style="margin-top: -120px;">
    <v-card-title class="d-flex justify-center">
      <v-img src="@/assets/logo.svg" max-width="10rem" class="justify-center"></v-img>
    </v-card-title>
    <v-card-title>
      {{$t('register')}}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
      >
        <v-container>
          <v-text-field
            v-model="form.name"
            :label="$t('profileName')"
            :rules="[rules.required]"
            required
          />
          <v-text-field
            v-model="form.email"
            :label="$t('email')"
            :rules="[rules.required, rules.email]"
            required
          />
          <v-text-field
            v-model="form.password"
            :label="$t('password')"
            :rules="[rules.required, rules.min6]"
            type="password"
            required
          />
          <v-text-field
            ref="rePassword"
            v-model="form.rePassword"
            :label="$t('rePassword')"
            :rules="[rules.required, rules.sameWithPassword]"
            type="password"
            required
          />
          <div v-if="error" class="subtitle-2 red--text">
            {{$t(`errorCodes.${[error.code]}`)}}
          </div>
          <v-row class="justify-start align-center mt-2">
            <v-checkbox
              v-model="clarificationTextValue"
            >
            </v-checkbox>
            <span>
              <a @click="clarificationTextModal = !clarificationTextModal">
                Aydınlatma Metni
              </a>'ni okudum, onaylıyorum.
            </span>
          </v-row>
          <v-row class="justify-start align-center mt-0">
            <v-checkbox
              v-model="clearConsentValue"
            >
            </v-checkbox>
            <span>
              <a @click="clearConsentModal = !clearConsentModal">
                Açık Rıza Metni
              </a>'ni okudum, onaylıyorum.
            </span>
          </v-row>
          <v-row class="justify-end mt-2">
            <v-btn
              @click="submitForm"
              justify-end
              :loading="loading"
              :disabled="!clarificationTextValue"
            >{{$t('register')}}</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
  <v-dialog
    v-model="clarificationTextModal"
    width="600"
  >
    <ClarificationText/>
  </v-dialog>
  <v-dialog
    v-model="clearConsentModal"
    width="600"
  >
   <ClearConsent/>
  </v-dialog>
</v-container>
</template>

<script>
import ClarificationText from '@/components/ClarificationText.vue'
import ClearConsent from '../../components/ClearConsent.vue'
export default {
  components: {
    ClarificationText,
    ClearConsent
  },
  data () {
    return {
      isFormValid: false,
      clarificationTextModal: false,
      clarificationTextValue: false,
      clearConsentModal: false,
      clearConsentValue: false,
      form: {
        name: '',
        email: '',
        password: '',
        rePassword: ''
      },
      rules: {
        required: value => !!value || this.$t('formErrors.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('formErrors.email')
        },
        min6: value => {
          if (this?.$refs?.rePassword?.validate) this.$refs.rePassword.validate()
          return (value && value.length >= 6) || this.$t('formErrors.min6')
        },
        sameWithPassword: value => {
          return (value && this.form.password === value) || this.$t('formErrors.rePassword')
        }
      }
    }
  },
  computed: {
    loading () {
      return (this.$store.state.auth.register.status === 'loading')
    },
    error () {
      return this.$store.state.auth.register.error
    }
  },
  methods: {
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    submitForm () {
      if (!this.$refs.form.validate()) return null

      const formData = {
        name: this.form.name,
        email: this.form.email,
        password: this.form.password,
        publicKey: this.$route.params.public_key,
        privateKey: this.$route.params.private_key
      }

      this.$store.dispatch('auth/register', formData).then((a) => {
        this.$refs.form.reset()
        this.$router.replace({ name: 'Profile' })
      })
    }
  }
}
</script>
