<template>
  <v-card>
    <div class="px-5 py-5">
      <h3 class="text-center mb-5">Kullanıcılara İlişkin Kişisel Verilerin Korunması Aydınlatma Metni</h3>
      <p>
        IDY Teknoloji Bilişim Limited Şirket (“Şirket”), 6698 sayılı Kişisel Verilerin Korunması Kanunu
        (“KVKK”) uyarınca, veri sorumlusu sıfatı ile kişisel verilerinizi kaydedecek, saklayacak,
        güncelleyecek, mevzuatın izin verdiği durumlarda üçüncü kişilere açıklayabilecek ve
        sınıflandırılabilecektir.
      </p>
      <p class="mt-3">
        Konuya ilişkin ayrıntılar ve haklarınız, bilgilendirme amaçlı olarak aşağıda özetlenmiştir.
      </p>
      <p class="mt-3">
        <b>1. KİŞİSEL VERİLERİN İŞLENME AMACI VE HUKUKİ SEBEBİ</b>
      </p>
      <p>
        Sizlere ait kimlik, iletişim, yaş, cinsiyet, meslek, müşteri işlem, lokasyon, ödeme, görsel ve işitsel
kayıtlar, sosyal medya hesapları ve paylaşmanız halinde banka hesap bilgileri, CV ve benzeri
sistem üzerinde kendi ihtiyarınız doğrultusunda eklediğiniz tüm bilgileriniz de dâhil olmak üzere
tarafımızla paylaştığınız tüm kişisel verileriniz;
      </p>
     <p class="mt-3">
        <b>1.1. Bir sözleşmenin kurulması veya ifası ile doğrudan ilgili olarak kişisel verinizin işlenmesinin
gerekli olması ve Şirketimizin hukuki yükümlülüklerini yerine getirebilmesi için zorunlu olması
hukuki sebebine ilişkin olarak;</b>
      </p>
      <p>
       <ul>
        <li>Ürün veya hizmetlerin başvuru ve aktivasyon süreçlerinin oluşturulması veya yürütülmesi,</li>
        <li>Ürün veya hizmetlerin kullandırma süreçlerinin oluşturulması veya takibi,</li>
        <li>Ürün veya hizmetlerin satış süreçlerinin planlanması veya yürütülmesi,</li>
        <li>Ürün veya hizmetlerin tahsis veya değerlendirme süreçlerinin oluşturulması veya takibi,</li>
        <li>Ürün veya hizmetlere erişim veya kullanımda müşterinin kullanacağı kanallara uygun
      araçların veya bilgilerin müşterilere sağlanması süreçlerinin planlanması veya yürütülmesi,</li>
        <li>Sözleşme süreçlerinin veya hukuki taleplerin takibi,</li>
        <li>Müşteri ilişkileri yönetimi süreçlerinin planlanması veya yürütülmesi,</li>
        <li>Satış sonrası destek hizmetleri aktivitelerinin planlanması veya yürütülmesi,</li>
        <li>Tedarik zinciri yönetimi süreçlerinin planlanması veya yürütülmesi,</li>
        <li>Şirketimizle aranızdaki her türlü ticari ve hukuki ilişkinin düzenlenmesi veya ifası,</li>
        <li>Şirketimizin sağladığı hizmetlerden kolaylıkla yararlanabilmenizin temin edilmesi
      amaçlarıyla;</li>
        </ul>
      </p>
      <p class="mt-3">
        <b>1.2. Şirketimizin veri sorumlusu sıfatıyla hukuki yükümlülüklerini yerine getirebilmesi için
zorunlu olması ve kanunlarda açıkça öngörülmesi hukuki sebebine ilişkin olarak;
o</b>
      </p>
      <p>
       <ul>
          <li>Şirketimizin faaliyetlerinin kendi iç düzenlemelerine ve ilgili mevzuata uygun olarak
yürütülmesinin temini,</li>
          <li>Şirketimizin ürün ve hizmetleri ile, operasyonel faaliyetlerinin planlanması veya
        yürütülmesi,</li>
          <li>Yetkili kişi veya kuruluşlara mevzuattan kaynaklı bilgi verilmesi,</li>
          <li>Çalışanlarla ilgili etik dışı davranış veya suiistimal vakaları hakkında gerekli operasyonel
        faaliyetlerin planlanması veya yürütülmesi,</li>
          <li>Çalışanların bilgiye erişim yetkilerinin planlanması veya yürütülmesi,</li>
          <li>Bilgi teknolojileri alt yapısının ve süreçlerinin oluşturulması, yönetilmesi veya denetimi,</li>
          <li>Sistem ve operasyonların güvenliğinin temini,</li>
          <li>İş ortakları veya tedarikçilerin bilgiye erişim yetkilerinin planlanması veya yürütülmesi,</li>
          <li>Verilerin doğru ve güncel olmasının temini,</li>
          <li>Mevzuatın öngördüğü bilgi saklanması, raporlanması ve diğer yükümlülüklere uyulması
        amaçlarıyla;</li>
        </ul>
      </p>
      <p class="mt-3">
        <b>1.3. İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla; Şirketimizin meşru
menfaatleri için veri işlemesinin zorunlu olması hukuki sebebine ilişkin olarak;</b>
      </p>
       <p>
        <ul>
          <li>İç veya dış paydaşlarla paylaşılacak basılı, görsel veya işitsel iletişim faaliyetlerinin
yürütülmesi,</li>
          <li>İş ortakları, tedarikçiler ve iştiraklerle olan ilişkilerin yönetimi,</li>
          <li>İş faaliyetlerinin planlanması veya yürütülmesi,</li>
          <li>İş sürekliliğinin sağlanması faaliyetlerinin planlanması veya yürütülmesi,</li>
          <li>Pazar araştırması faaliyetlerinin planlanması veya yürütülmesi,</li>
          <li>Acil durum veya olay yönetimi süreçlerinin planlanması veya yürütülmesi,</li>
          <li>Operasyon veya verimlilik süreçlerinin planlanması veya yürütülmesi,</li>
          <li>Lojistik faaliyetlerinin planlanması veya yürütülmesi,</li>
          <li>Şirketimizin hedefleri doğrultusunda projelerinin planlanması veya yürütülmesi,</li>
          <li>Stratejik planlama faaliyetlerinin yürütülmesi,</li>
        </ul>
       </p>
      <p class="mt-3">
        <b>1.4. Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki
sebebine ilişkin olarak;</b>
      </p>
       <p>
        <ul>
          <li>Hukuki dava ve icra işlerinin planlanması, izlenmesi veya takip edilmesi,</li>
          <li>Müşteri talep veya şikâyetlerinin takip edilmesi veya sonuçlandırılması amaçlarıyla;</li>
        </ul>
       </p>
       <p class="mt-3">
        <b>1.5. Açık rızanızın varlığı halinde;</b>
      </p>
       <p>
        <ul>
          <li>Müşteri memnuniyeti ve tecrübesine yönelik aktivitelerin planlanması veya
          gerçekleştirilmesi,</li>
          <li>Ürün veya hizmetlerin pazarlanma süreçlerinin planlanması veya yürütülmesi,</li>
          <li>Pazarlama amacıyla veri analitiği çalışmalarının yapılması,</li>
          <li>Şirketimizin sunduğu ürün ve hizmetlere bağlılık oluşturulması veya arttırılması süreçlerinin
          planlanması veya yürütülmesi,</li>
          <li>Dijital veya diğer mecralarda pazarlama, tanıtım veya reklam aktivitelerinin planlanması
          veya yürütülmesi,</li>
          <li>Kampanya veya promosyon süreçlerinin planlanması veya yürütülmesi,</li>
          <li>Şirketimizin tarafından sunulan ürünlere ilişkin çapraz satış aktivitelerinin planlanması veya
          yürütülmesi,</li>
          <li>Kişiye özel pazarlama veya tanıtım aktivitelerinin tasarlanması veya yürütülmesi amaçlarıyla
          İşlenmektedir.</li>
        </ul>
       </p>
       <p class="mt-3">
        <b>2. KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMLERİ</b>
      </p>
      <p>
        Kişisel verileriniz; ilgili mevzuat hükümlerinin çizdiği sınırlar/yükümlülükler dâhilinde onayınızla
tanzim edilen tüm sözleşmeler/bilgilendirme formları ve sair belgelerle, elektronik onay ile
yapacağınız bildirimlerle, hakkınızdaki yargı kararlarının, icra takiplerinin Şirketimize iletilmesiyle,
kısa mesaj, elektronik posta, internet ve benzeri her türlü araç veya ortamla, telefon, faks ve
benzeri her türlü iletişim aracıyla, genel müdürlüğümüz ve birimlerimiz gibi kanallar aracılığıyla,
sunulan hizmetin gerekliliklerine uygun olmak üzere yazılı veya sözlü olarak toplanabilecektir.
Bu verileriniz elektronik onay, kısa mesaj, elektronik posta, internet ve benzeri elektronik ya da
internet tabanlı araçlar gibi otomatik yöntemlerle elde edilebileceği gibi, şirketimize sunulan
formlar, sözleşmeler, bildirimler, adli veya idari merci kararları gibi otomatik olmayan
yöntemlerle de elde edilebilecektir.
      </p>
      <p class="mt-3">
        <b>3. KİŞİSEL VERİLERİNİZİN AKTARIMI</b>
      </p>
      <p>
        İşlenen kişisel verileriniz; yukarıdaki amaçlarla mevzuatın izin verdiği tüm gerçek ve tüzel
kişilere, internet sitemizde bulunan tedarikçilerimize, iş ilişkisinde bulunduğumuz tüm gerçek ve
tüzel kişilere, kişisel verileri almaya yetkisi bulunan tüm kamu otoritelerine, yurt içi ve yurt
dışındaki hissedarlarımıza, iştiraklerimize, bağlı ortaklıklarımıza, hizmet/destek/danışmanlık
alınan kuruluşlara Kişisel Verilerin Korunması Kanununda yer alan hukuki sebeplere uygun
şekilde aktarılabilecektir.
      </p>
      <p class="mt-3">
        <b>4. KİŞİSEL VERİLERİNİZE İLİŞKİN HAKLARINIZ</b>
      </p>
      <p>
        6698 sayılı Kanun’un 11. maddesi kapsamında Şirketimize başvurarak;
      </p>

      <p>
        <ul>
          <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
          <li>Kişisel verileriniz işlenmiş ise buna ilişkin bilgi talep etme,</li>
          <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
        öğrenme,</li>
          <li>Yurtiçinde veya yurtdışında kişisel verilerinizin aktarıldığı üçüncü kişileri öğrenme,</li>
          <li>Kişisel verilerinizin eksik veya yanlış işlenmesi halinde bunların düzeltilmesini isteme,</li>
          <li>6698 sayılı Kanunun 7. maddesinde öngörülen şartlar dahilinde kişisel verilerinizin
        silinmesini veya yok edilmesini isteme,</li>
          <li>Kişisel verilerinizin yukarıdaki sebeplerle düzeltilmesi, silinmesi veya yok edilmesi halinde
        bunların aktarıldığı üçüncü kişilere bilgi verilmesini isteme,</li>
          <li>İşlenen kişisel verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
        suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
         <li>Kişisel verilerinizin Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde
        zararınızın giderilmesini talep etme haklarına sahipsiniz.</li>
        </ul>
      </p>
      <p>
        6698 sayılı KVKK‘nun 11. maddesi kapsamındaki talepleriniz için, kimliğinizi ve talebinizi içeren
dilekçenizi Şirketimize elden teslim edebilir, noter kanalı ile Cihangir Mah. E-5 Yanyol (Londra
Asfalt) Cad. No: 291/1 İç Kapı No: 11 Avcılar / İstanbul adresine ya da Şirketimizde kayıtlı e-posta
adresinizi kullanmak şartı ile <b>destek@idycard.com</b> e-posta adresine iletebilirsiniz.
Yukarıdaki haklarınızı kullanırken, talebinizden kaynaklanan işlemin ek bir maliyet gerektirmesi
halinde ilgili mevzuat uyarınca ücret alınacaktır.
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  mounted () {
    if (window.location.pathname.includes('/clarification')) {
      document.head.innerHTML += `
      <style>
        html, body {
            overflow-y: auto !important;
          }
        </style>
      `
    }
  }
}
</script>
