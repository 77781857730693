<template>
  <vue-pdf-embed
    v-if="this.$route.query.url"
    :source="this.$route.query.url"
  />
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: { VuePdfEmbed },
  data: function () {
    return {
      loading: false,
      snackbarData: {
        snackbar: false,
        text: '',
        color: 'green',
        timeout: 3000
      }
    }
  }
}
</script>

<style scoped>
  html, body {
    overflow-y: auto !important;
  }

  .app, .bottom-navigation {
    max-width: 95% !important;
  }

  .page {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }

  .app {
    width: 100% !important;
  }

  .bottom-navigation {
    left: unset !important;
    transform: unset !important;
  }
</style>
