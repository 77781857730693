<template>
  <v-app class="app">
    <v-main>
      <keep-alive>
      <router-view class="page" />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import interceptorsSetup from './utils/interceptors'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  beforeMount () {
    interceptorsSetup()
  }
}
</script>

<style scoped>
  .page {
    padding-top: 0.5rem !important;
    padding-bottom: 4rem !important;
    max-width: 30rem !important;
  }
</style>

<style>
  html, body {
    overflow-y: hidden !important;
  }

  body {
    display: flex !important;
    justify-content: center !important;
  }

  .app, .bottom-navigation {
    max-width: 30rem !important;
  }
  .app {
    width: 100% !important;
    max-width: 30rem !important;
  }
  .bottom-navigation {
    left: 50% !important;
    transform: translateX(-50%) !important;
    max-width: 30rem !important;
  }
  .container {
    max-width: 30rem !important;
  }
  .v-bottom-navigation {
    max-width: 30rem !important;
  }
</style>

<style lang="scss">
.v-application {
    font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
    .title {
      font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
    }
    .subtitle-2 {
      font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
    }
  }
</style>
