import axios from '@/utils/axios'
const activeCardId = localStorage.getItem('activeCardId')

export const cards = {
  state: {
    activeCardId: activeCardId ? parseInt(activeCardId) : null
  },
  getters: {
    active_card_id: (state) => state.activeCardId
  },
  mutations: {
    change_active_card_id (state, payload) {
      state.activeCardId = payload
      localStorage.setItem('activeCardId', payload)
    }
  },
  actions: {
    set_active_card_id ({ commit, state }, payload) {
      commit('change_active_card_id', payload)
    }
  }
}
