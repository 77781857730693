export default {
  login: 'Giriş Yap',
  logout: 'Çıkış Yap',
  register: 'Kayıt Ol',
  email: 'E-posta:',
  password: 'Şifre:',
  rePassword: 'Şifre (Onay):',
  formErrors: {
    required: 'Bu alan boş bırakılamaz.',
    email: 'Lütfen doğru bir E-posta adresi giriniz.',
    min6: 'Bu alan minimum 6 karakter olmalıdır.',
    rePassword: 'Girilen şifreler aynı olmalıdır.',
    error: 'Lütfen doğru bir değer giriniz.',
    fileSizeError: "Dosya 20 MB'den büyük."
  },
  profileName: 'İsim:',
  location_or_title: 'Lokasyon veya Başlık:',
  description: 'Hakkımda:',
  addNewItemInfo: 'Dijital kartvizitinize kişisel bilgiler, bağlantılar ve daha fazlasını ekleyin',
  addNewItem: 'İçerik Ekle',
  direct: 'Direkt',
  save: 'Kaydet',
  errorCodes: {
    1: 'Beklenmedik bir hata oluştu, lütfen daha sonra tekrar deneyin.',
    88001: 'Kullanıcı bulunamadı!',
    99182: 'Bilinmeyen dosya tipi!',
    612184: 'Bilinmeyen dosya tipi!',
    99036: 'Resim kaydedilirken bir hata oluştu!'
  },
  myCards: 'Kartlarım',
  add: 'Ekle',
  update: 'Güncelle',
  fieldName: 'Bağlantı Adı:',
  cropImage: 'Resmi Kırp',
  editProfile: 'Profili Düzenle',
  cancel: 'İptal',
  crop: 'Kırp',
  fileInput: 'Dosya:',
  delete: 'Sil',
  preview: 'Ön İzleme',
  profile: 'Profil',
  settings: 'Ayarlar',
  hideMyProfile: 'Profilimi Gizle',
  close: 'Kapat',
  pageNotFound: 'Sayfa Bulunamadı!',
  forgotPassword: 'Şifremi Unuttum',
  sendRecoveryEmail: "Kurtarma E-posta'sı Gönder",
  resetPassword: 'Yeni Şifre Oluştur',
  newPassword: 'Yeni Şifre',
  newPasswordConfirm: 'Yeni Şifre (Onay)',
  newPasswordNotMatch: 'Girdiğiniz şifreler eşleşmiyor.',
  confirm: 'Onayla',
  changePassword: 'Şifremi Değiştir',
  oldPassword: 'Eski Şifre',
  file: 'Dosya',
  bank: 'Banka',
  telephone: 'Telefon',
  name: 'İsim',
  address: 'Adres',
  other: 'Diğer',
  text: 'Metin',
  social_media: 'Sosyal Medya',
  communication: 'İletişim',
  youtube_channel: 'YouTube Kanalı',
  web_url: 'Web Adresi',
  country: 'Ülke',
  selectCountry: 'Ülke Seçiniz',
  phoneNumber: 'Telefon Numarası',
  example: 'Örnek :',
  deleteAccountTitle: 'Hesabı Sil',
  deleteAccountMessage: 'Hesabınızı silmek istediğinize emin misiniz?',
  deleteAccount: 'Hesabı Sil',
  yes: 'Evet',
  no: 'Hayır',
  deleteAccountSuccess: 'Hesabınız başarıyla silindi.',
  deleteAccountError: 'Hesabınızı silerken bir hata oluştu.',
  editCard: 'Düzenle',
  deleteCard: 'Kartı Sil',
  qrCode: 'QR Kod',
  share: 'Paylaş',
  addNewCard: 'Yeni Kart Ekle',
  deleteCardTitle: 'Kartı Sil',
  deleteCardMessage: 'Bu kartı silmek istediğinize emin misiniz?',
  deleteCardSuccess: 'Kart başarıyla silindi.',
  deleteCardError: 'Kartı silerken bir hata oluştu.',
  editCardTitle: 'Kartı Düzenle',
  editCardMessage: 'idyCard\'ınıza yeni bir isim verin',
  editCardInputPlaceholder: 'Kart adınızı giriniz',
  editCardSuccess: 'Kart başarıyla güncellendi.',
  editCardError: 'Kartı güncellerken bir hata oluştu.'
}
