<template>
  <div v-if="item" class="edit-item-view">
    <app-bar :title="pageName" backButton>
      <v-main class="add-item-view">
        <page>
          <v-col class="px-0 mb-10">
            <template v-if="item.regex === 'file'">
              <div class="d-flex flex-column mt-2 mb-3" style="align-items: center;">
                <v-img v-if="customIconUrl" width="6rem" :src="customIconUrl"></v-img>
                <v-img v-else width="50px" :src="item.icon_url"></v-img>
                <h1 class="overline icon-lbl">{{$t(item.name)}}</h1>
              </div>
            </template>
            <template v-else>
              <v-card flat class="my-10">
                <v-col class="px-0">
                  <v-row justify="center">
                    <v-btn @click="handleFileImport()" icon absolute bottom fab :ripple="false">
                      <div class="d-flex flex-column mb-3" style="align-items: center;">
                          <v-avatar absolute bottom fab rounded size="6rem" align="center">
                            <v-img v-if="croppedFileBase64" width="50px" :src="croppedFileBase64"></v-img>
                            <v-img v-else-if="customIconUrl" width="50px" :src="customIconUrl"></v-img>
                            <v-img v-else-if="item.icon_url" width="50px" :src="item.icon_url"></v-img>
                            <v-overlay absolute>
                              <v-icon>mdi-cloud-upload-outline</v-icon>
                            </v-overlay>
                            <input
                              ref="uploader"
                              class="d-none"
                              type="file"
                              @change="onFileChanged"
                              accept="image/*;capture=camera"
                            >
                          </v-avatar>
                      </div>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-card>
              <h1 class="overline text-center icon-lbl mx-0 my-0 px-0 py-0">{{itemFields.name ? itemFields.name : $t(item.name)}}</h1>
            </template>
            <v-spacer style="margin-top: 1rem;"></v-spacer>
            <v-form ref="form" v-model="isFormValid" lazy-validation>
              <v-text-field
                :disabled="item.regex === 'file'"
                filled
                rounded
                class="rounded-lg"
                :label="$t('fieldName')"
                v-model="itemFields.name"
                :rules="[rules.required]"
              ></v-text-field>
              <template v-if="item.regex === 'file'">
                <!-- <v-file-input
                  v-model="itemFields.file"
                  class="file-input rounded-lg"
                  color="deep-purple accent-4"
                  filled
                  label="Yeni Dosya"
                  placeholder="Select your file"
                  append-icon="mdi-paperclip"
                  :prepend-icon="null"
                  outlined
                  :show-size="1000"
                  :rules="[rules.required, validateFieldWithRegex(itemFields.data, item.regex)]"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input> -->
              </template>
              <template v-else-if="item.format && typeof item.format == 'object'">
                <v-text-field
                  v-for="fieldName in Object.keys(item.format)"
                  :key="fieldName"
                  filled
                  rounded
                  class="text-field-input rounded-lg"
                  :label="$t(fieldName)"
                  v-model="itemFields.formatted_data[fieldName]"
                  :rules="[rules.required, validateFieldWithRegex(itemFields.formatted_data[fieldName], item.regex)]"
                ></v-text-field>
              </template>
              <template v-else-if="item.input_type_id === 5">
                <v-container v-if="item.prefix" class="mt-3">
                  <v-row>
                    <v-col cols="auto" class="text-end pa-0">
                      <p><b>{{$t('preview')}}: </b></p>
                    </v-col>
                    <v-col cols="auto" class="text-start pa-0 pl-1">
                      <a :href="item.prefix + (itemFields.data || '') + (item.postfix || '')" target="_blank">
                        <p>{{item.prefix}}<b>{{itemFields.data || '***'}}</b>{{item.postfix || ''}}</p>
                      </a>
                    </v-col>
                  </v-row>
                </v-container>
                <vue-phone-number-input
                  class="mb-10"
                  size="lg"
                  :border-radius="8"
                  clearable
                  required
                  :error="!phoneIsValid"
                  :rules="[rules.required, validateFieldWithRegex(itemFields.data, item.regex)]"
                  default-country-code="TR"
                  :translations="{
                    countrySelectorLabel: 'Ülke',
                    countrySelectorError: 'Ülke seçiniz',
                    phoneNumberLabel: 'Telefon Numarası',
                    example: 'Örnek :'
                  }"
                  v-model="phone"
                  @update="phoneChange"
                />
              </template>
              <template v-else>
                <v-container v-if="item.prefix" class="mt-3">
                  <v-row>
                    <v-col cols="auto" class="text-end pa-0">
                      <p><b>{{$t('preview')}}: </b></p>
                    </v-col>
                    <v-col cols="auto" class="text-start pa-0 pl-1">
                      <a :href="item.prefix + (itemFields.data || '') + (item.postfix || '')" target="_blank">
                        <p>{{item.prefix}}<b>{{itemFields.data || '***'}}</b>{{item.postfix || ''}}</p>
                      </a>
                    </v-col>
                  </v-row>
                </v-container>
                <v-text-field
                  filled
                  rounded
                  class="rounded-lg"
                  :label="$t(item.name)"
                  v-model="itemFields.data"
                  :rules="[rules.required, validateFieldWithRegex(itemFields.data, item.regex)]"
                ></v-text-field>
              </template>
              <div v-if="errorCode" class="subtitle-2 red--text pb-4 px-2">
                {{$t(`errorCodes.${[errorCode]}`)}}
              </div>
              <v-btn
                v-if="item.regex != 'file'"
                block
                color="#2f3542"
                depressed
                class="white--text rounded-lg py-6"
                :loading="loading"
                @click="submit"
                >{{ $t("update") }}</v-btn
              >
              <v-btn
                block
                color="#f04a4a"
                depressed
                class="white--text rounded-lg py-6 mt-2"
                :loading="loading"
                @click="deleteField"
                >{{ $t("delete") }}</v-btn
              >
            </v-form>
          </v-col>
        </page>
      </v-main>
      <v-dialog
        v-model="cropperDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{$t('cropImage')}}
          </v-card-title>
          <picture-cropper
            :img="selectedProfilePicture"
            @croppedFile="handleCroppedFile"
            :cancelButtonAction="closeDialog"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </app-bar>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Page from '@/components/Page.vue'
import axios from '@/utils/axios'
import byteToMB from '@/utils/byteToMB'
import PictureCropper from '@/components/PictureCropper.vue'
import heic2any from 'heic2any'
import { compress } from 'image-conversion'

export default {
  components: {
    AppBar,
    Page,
    PictureCropper
  },
  data () {
    return {
      phone: null,
      phoneIsValid: false,
      isFormValid: false,
      pageName: '',
      item: null,
      customIconUrl: '',
      itemFields: {
        name: '',
        data: '',
        formatted_data: {},
        file: null
      },
      loading: false,
      errorCode: null,
      rules: {
        required: (value) => !!value || this.$t('formErrors.required')
      },
      cropperDialog: false,
      croppedFile: null,
      croppedFileBase64: null,
      isSelectingProfileImage: false,
      selectedProfilePicture: ''
    }
  },
  mounted () {
    this.getField()
  },
  beforeDestroy () {
    this.$refs.form.reset()
    this.errorCode = null
  },
  methods: {
    phoneChange (data) {
      this.phoneIsValid = false
      if (data.countryCallingCode && data.nationalNumber && data.isValid) {
        this.itemFields.data = `${data.countryCallingCode}${data.nationalNumber}`
        this.phoneIsValid = data.isValid
      }
    },
    validateFieldWithRegex (value, regex) {
      if (regex === 'file') {
        const file = this.itemFields.file
        if (!file) return this.$t('formErrors.error')
        if (byteToMB(file.size) > 20) return this.$t('formErrors.fileSizeError')
        return true
      } else if (regex.length) {
        const pattern = new RegExp(regex)
        return (!!value && pattern.test(value)) || this.$t('formErrors.error')
      }
      return true
    },
    getField () {
      axios.get(`/card/${this.$route.params.cardId}`).then((res) => {
        if (res?.data?.data) {
          this.card = res.data.data
          // eslint-disable-next-line eqeqeq
          const item = this.card.fields.find((i) => i.id == this.$route.params.itemId)
          this.customIconUrl = item.custom_icon_url
          this.pageName = this.$t(item.name)
          this.itemFields.name = this.$t(item.name)
          if (item.field_type.input_type_id === 5) {
            this.phone = `+${item.data}`
          }
          this.itemFields.data = item.data
          this.item = item.field_type
          this.itemFields.formatted_data = item.formatted_data

          if (!item.data && item.formatted_data) {
            this.itemFields.data = item.formatted_data
          }
        }
      })
    },
    async submit () {
      if (!this.$refs.form.validate()) return
      if (this.item.input_type_id === 5) {
        if (!this.phoneIsValid) return
      }
      this.loading = true
      this.errorCode = null

      let useFormData = false
      const formData = new FormData()
      const postData = {
        name: this.itemFields.name
      }

      if (this.itemFields.formatted_data && Object.keys(this.itemFields.formatted_data).length) {
        postData.formatted_data = this.itemFields.formatted_data
      } else {
        postData.data = this.itemFields.data
      }

      if (this.itemFields.file) {
        useFormData = true
        Object.keys(postData).forEach(key => {
          formData.append(key, postData[key])
        })
        formData.append('file', this.itemFields.file)
        if (this.croppedFile) {
          const compressedFile = await compress(this.croppedFile, {
            quality: 0.8,
            width: 720,
            height: 720,
            type: 'image/jpeg'
          })

          formData.append('custom_icon', compressedFile)
        }
      } else {
        if (this.croppedFile) {
          useFormData = true
          if (this.itemFields.formatted_data && Object.keys(this.itemFields.formatted_data).length) {
            formData.append('formatted_data', JSON.stringify(this.itemFields.formatted_data))
          } else {
            formData.append('data', this.itemFields.data)
          }
          formData.append('name', this.itemFields.name)

          const compressedFile = await compress(this.croppedFile, {
            quality: 0.8,
            width: 720,
            height: 720,
            type: 'image/jpeg'
          })

          formData.append('custom_icon', compressedFile)
        }
      }

      return new Promise((resolve, reject) => {
        axios.put(`/card/${this.$route.params.cardId}/field/${this.$route.params.itemId}`,
          useFormData ? formData : postData
        ).then((res) => {
          if (res?.data?.data) {
            this.$router.replace({ name: 'Profile', params: { cardId: this.$route.params.cardId } })
          }
        }).catch((err) => {
          if (err?.response?.status === 404) {
            this.errorCode = err.response.data.code
          } else {
            this.errorCode = 1
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    deleteField () {
      this.loading = true
      this.errorCode = null
      return new Promise((resolve, reject) => {
        axios.delete(`/card/${this.$route.params.cardId}/field/${this.$route.params.itemId}`).then((res) => {
          this.$router.replace({ name: 'Profile' })
        }).catch((err) => {
          if (err?.response?.status === 404) {
            this.errorCode = err.response.data.code
          } else {
            this.errorCode = 1
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    handleFileImport () {
      this.isSelectingProfileImage = true

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
        this.isSelectingProfileImage = false
      }, { once: true })
      // Trigger click on the FileInput
      this.$refs.uploader.click()
    },
    async onFileChanged (e) {
      let img = e.target.files[0]
      if (img.type === 'image/heic') {
        const blobURL = URL.createObjectURL(img)
        const blobRes = await fetch(blobURL)
        const blob = await blobRes.blob()
        img = await heic2any({ blob })
      }

      this.selectedProfilePicture = URL.createObjectURL(img)
      this.cropperDialog = true
    },
    handleCroppedFile ({ file, base64Image }) {
      this.croppedFile = file
      this.croppedFileBase64 = base64Image
      this.closeDialog()
    },
    closeDialog () {
      this.isSelectingProfileImage = false
      this.selectedProfilePicture = ''
      this.$refs.uploader.value = null
      this.cropperDialog = false
    }
  }
}
</script>

<style lang="scss">
.edit-item-view {
  .file-input {
    .v-file-input__text {
      padding-top: 0 !important;
    }
    .v-input__slot {
      padding-left: 24px !important;
    }
    fieldset {
      border: none !important;
    }
  }
}
.icon-lbl {
    text-transform: none !important;
  }
</style>
