<template>
  <v-col class="px-0">
    <v-card flat>
      <v-col class="px-0 py-0">
        <v-row justify="center">
          <v-btn @click="openVcfCard" dark v-if="!editable" absolute bottom color="black" right fab>
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-btn dark v-else absolute bottom color="blue" right fab @click="$router.push({name: 'Edit Profile'})">
            <v-icon v-if="editable">mdi-pencil</v-icon>
          </v-btn>
          <v-btn class="avatar adasd" absolute bottom fab :ripple="false">
            <v-avatar absolute bottom fab size="10rem" align="center">
              <v-img v-if="avatar" :src="avatar" style="border: 5px solid white;"></v-img>
              <v-img v-else src="@/assets/profile.webp"></v-img>
            </v-avatar>
          </v-btn>
        </v-row>
        <v-img
          max-height="10rem"
          src="@/assets/forest.webp"
        >
          <v-container class="fill-height">
            <v-row align="center">
              <v-row justify="center">
              </v-row>
            </v-row>
          </v-container>
        </v-img>
      </v-col>
    </v-card>
    <v-card-title v-if="name" class="d-flex justify-center align-center" style="margin-top: 5rem;">
      {{name}}
    </v-card-title>

    <v-card-subtitle v-if="title" class="py-0" align="center">
      {{title}}
    </v-card-subtitle>

    <v-card-subtitle v-if="description" class="py-1" align="center">
      {{description}}
    </v-card-subtitle>
  </v-col>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    fields: {
      type: Array,
      required: false
    }
  },
  methods: {
    openVcfCard () {
      window.location.href = `https://api.idycard.com/card/${this.$route.params.cardId}/vcard`
    }
  }
}
</script>

<style scoped>
.avatar {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
