import axios from '@/utils/axios'
const auth = JSON.parse(localStorage.getItem('auth'))
const jwt = localStorage.getItem('jwt')

export const login = {
  state: {
    user: auth?.user ? auth.user : null,
    refresh_token: auth?.refresh_token ? auth.refresh_token : null,
    jwt: jwt || null,
    status: undefined,
    error: null
  },
  getters: {
    jwt: (state) => state.jwt
  },
  mutations: {
    login_data (state, payload) {
      state.user = payload.user
      state.refresh_token = payload.refresh_token
      localStorage.setItem('auth', JSON.stringify(payload))
    },
    login_status (state, payload) {
      state.status = payload
    },
    login_error (state, payload) {
      state.error = payload
    },
    jwt_data (state, payload) {
      state.jwt = payload
      localStorage.setItem('jwt', payload)
    }
  },
  actions: {
    login ({ commit, dispatch }, data) {
      const { email, password } = data
      commit('login_error', null)
      commit('login_status', 'loading')
      return new Promise((resolve, reject) => {
        axios.post('/user/login', { email, password })
          .then((res) => {
            if (res.data) {
              commit('login_status', 'ok')
              commit('login_data', res.data.data)
              resolve(res.data)
            } else {
              const errorData = {
                code: '1',
                error: 'unknown error'
              }
              commit('login_error', errorData)
              reject(errorData)
            }
          }).catch((err) => {
            commit('login_status', 'error')
            commit('login_error', err.response.data)
            reject(err.response.data)
          })
      })
    },
    refreshJwt ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/user/token', {
          token: state.refresh_token?.token
        }).then((res) => {
          if (res?.data?.data?.token) {
            commit('jwt_data', res.data.data.token)
            return resolve(res.data)
          } else {
            reject(new Error('Unexpected error!'))
          }
        }).catch(() => {
          reject(new Error('UNAUTHORIZED!'))
        })
      })
    }
  }
}

export const register = {
  state: {
    status: undefined,
    error: null
  },
  getters: {},
  mutations: {
    register_status (state, payload) {
      state.status = payload
    },
    register_error (state, payload) {
      state.error = payload
    }
  },
  actions: {
    register ({ commit }, data) {
      const { name, email, password, publicKey, privateKey } = data
      commit('register_status', 'loading')
      return new Promise((resolve, reject) => {
        axios.post('/user',
          { name, email, password, public_key: publicKey, private_key: privateKey }
        ).then((res) => {
          if (res.data) {
            commit('register_status', 'ok')
            commit('login_data', res.data.data)
            resolve(res.data)
          } else {
            const errorData = {
              code: '1',
              error: 'unknown error'
            }
            commit('register_error', errorData)
            reject(errorData)
          }
        }).catch((err) => {
          commit('register_status', 'error')
          commit('register_error', err.response.data)
          reject(err.response.data)
        })
      })
    }
  }
}

export const forgotPassword = {
  state: {
    status: undefined,
    error: null
  },
  getters: {},
  mutations: {
    forgot_password_status (state, payload) {
      state.status = payload
    },
    forgot_password_error (state, payload) {
      state.error = payload
    }
  },
  actions: {
    forgotPassword ({ commit, dispatch }, data) {
      const { email } = data
      commit('forgot_password_error', null)
      commit('forgot_password_status', 'loading')
      return new Promise((resolve, reject) => {
        axios.post('/user/reset_password_request', { email })
          .then((res) => {
            if (res.data) {
              commit('forgot_password_status', 'ok')
              resolve(res.data)
            } else {
              const errorData = {
                code: '1',
                error: 'unknown error'
              }
              commit('forgot_password_error', errorData)
              reject(errorData)
            }
          }).catch((err) => {
            commit('forgot_password_status', 'error')
            commit('forgot_password_error', err.response.data)
            reject(err.response.data)
          })
      })
    }
  }
}

export const resetPassword = {
  state: {
    status: undefined,
    error: null
  },
  getters: {},
  mutations: {
    reset_password_status (state, payload) {
      state.status = payload
    },
    reset_password_error (state, payload) {
      state.error = payload
    }
  },
  actions: {
    resetPassword ({ commit, dispatch }, data) {
      const { newPassword, token } = data
      commit('reset_password_error', null)
      commit('reset_password_status', 'loading')
      return new Promise((resolve, reject) => {
        axios.post('/user/reset_password', { password: newPassword, token })
          .then((res) => {
            if (res.data) {
              commit('reset_password_status', 'ok')
              resolve(res.data)
            } else {
              const errorData = {
                code: '1',
                error: 'unknown error'
              }
              commit('reset_password_error', errorData)
              reject(errorData)
            }
          }).catch((err) => {
            commit('reset_password_status', 'error')
            commit('reset_password_error', err.response.data)
            reject(err.response.data)
          })
      })
    }
  }
}
