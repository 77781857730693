<template>
  <v-bottom-navigation
    v-model="value"
    background-color="#f4f4f4"
    fixed
    class="bottom-navigation"
  >
    <v-btn :ripple="false" plain value="Profile" :to="{name: 'Profile'}">
      <span>{{ $t('profile') }}</span>

      <v-icon>mdi-account-outline</v-icon>
    </v-btn>

    <v-btn :ripple="false" plain value="My Cards" :to="{name: 'My Cards'}">
      <span>{{ $t('myCards') }}</span>

      <v-icon>mdi-cards-outline</v-icon>
    </v-btn>

    <v-btn :ripple="false" plain value="Settings" :to="{name: 'Settings'}">
      <span>{{ $t('settings') }}</span>

      <v-icon>mdi-cog-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({
    value: 'Profile'
  }),
  beforeMount () {
    if (this.$route?.meta?.tab) {
      this.value = this.$route.meta.tab
    }
  }
}
</script>

<style scoped>
.bottom-navigation {
  -webkit-box-shadow: 5px 0px 30px 0px rgba(163,163,163,0.6);
  -moz-box-shadow: 5px 0px 30px 0px rgba(163,163,163,0.6);
  box-shadow: 5px 0px 30px 0px rgba(163,163,163,0.6);
}
</style>
