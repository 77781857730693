<template>
  <page class="d-flex flex-column">
    <img class="pt-10" width="100%" src="@/assets/not_found.svg" :alt="$t('notFound')" />
    <p class="text-h4 mt-16">{{$t('pageNotFound')}}</p>
</page>
</template>

<script>
import Page from '@/components/Page.vue'
export default {
  components: { Page }
}
</script>
