<template>
  <div />
</template>
<script>
export default {
  mounted () {
    localStorage.removeItem('auth')
    localStorage.removeItem('jwt')
    setTimeout(() => {
      window.location = '/login'
    }, 10)
  }
}
</script>
