<template>
  <app-bar title="Card">
    <v-main>
      <page>
        <v-col class="px-0">
          <v-row v-if="fields.length" class="align-center justify-end">
            <div class="subtitle-2 mx-3">{{ $t('direct') }}</div>
            <v-switch
              v-model="isDirect"
              color="black"
              inset
              :ripple="false"
              @change="directModeOnChange"
            ></v-switch>
          </v-row>
          <template v-if="card && reRenderControl">
            <list-group
              v-model="fields"
              :card-id="this.$route.params.cardId"
              :isDirect="isDirect"
            />
          </template>
          <v-flex
            class="rounded-lg blue-grey lighten-5 py-4"
            @click="$router.push({ name: 'Add Item List' })"
          >
            <v-list-item value="content.name">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-center align-center">{{
                  $t("addNewItem")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
        </v-col>
      </page>
    </v-main>
  </app-bar>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import ListGroup from '@/components/ListGroup.vue'
import axios from '@/utils/axios'
import Page from '@/components/Page.vue'

export default {
  components: {
    AppBar,
    ListGroup,
    Page
  },
  data () {
    return {
      isDirect: false,
      directModeFieldId: null,
      card: null,
      fields: [],
      reRenderControl: true
    }
  },
  beforeMount () {
    this.getCard()
  },
  methods: {
    getCard () {
      return new Promise((resolve, reject) => {
        axios.get(`/card/${this.$route.params.cardId}`).then((res) => {
          if (res?.data?.data) {
            this.card = res.data.data

            if (this.card.direct_mode_field_id) {
              this.isDirect = true
              this.directModeFieldId = this.card.direct_mode_field_id
            }

            this.fields = res.data.data.fields.map((item) => {
              let isActive = false
              if (this.isDirect) {
                isActive = this.directModeFieldId === item.id
              } else {
                isActive = item.is_active
              }

              return {
                id: item.id,
                name: item.name,
                isActive,
                icon: item.field_type.icon_url,
                cardId: this.$route.params.cardId
              }
            })
            this.reRenderControl = false
            this.$nextTick(() => {
              this.reRenderControl = true
            })
            resolve(res.data.data)
          }
        }).finally(() => {
          this.$forceUpdate()
        })
      })
    },
    directModeOnChange () {
      if (!this.isDirect) {
        return axios.post(`/card/${this.$route.params.cardId}/direct_mode`, {
          card_field_id: null
        }).then(() => {
          this.getCard()
        }).catch(() => {
          this.isDirect = true
        })
      }
    }
  }
}
</script>
