<template>
  <v-list id="draggable-list">
    <template v-for="(item, i) in data">
      <list-item
        v-if="reRenderControl"
        :key="`list-item-${i}`"
        class="list-item my-3 list-item-round"
        v-model="data[i]"
        :divider="data.length - 1 !== i"
        :disableSwitch="!canSwitch(item)"
        :click="
          () => $router.push({ name: 'Edit Item', params: { itemId: item.id, cardId } })
        "
        :isDirect="isDirect"
      />
    </template>
  </v-list>
</template>

<script>
import axios from '@/utils/axios'
import Sortable from 'sortablejs'
import ListItem from './ListItem.vue'

export default {
  components: { ListItem },
  props: {
    value: {
      type: Array,
      required: true
    },
    isDirect: {
      type: Boolean,
      required: false,
      default: false
    },
    cardId: {
      type: [String, Number],
      requred: false,
      default: null
    }
  },
  data () {
    return {
      reRenderControl: true,
      sortableInstance: null,
      data: this.value
    }
  },
  mounted () {
    const el = document.getElementById('draggable-list')
    const _self = this
    // eslint-disable-next-line new-cap
    this.sortableInstance = new Sortable.create(el, {
      forceFallback: true,
      fallbackAxis: 'y',
      ghostClass: 'ghost',
      handle: '.draggable-icon',
      onUpdate: function (e) {
        _self.moveObjectOfArray(e.oldIndex, e.newIndex)
      }
    })
  },
  beforeDestroy () {
    this.sortableInstance.destroy()
  },
  methods: {
    async moveObjectOfArray (from, to) {
      const arr = [...this.data]
      arr.splice(to, 0, arr.splice(from, 1)[0])
      console.log(arr)
      const arrOrder = arr.map((i) => i.id)
      if (this.cardId) {
        await axios.put(`/card/${this.cardId}/field/order`, { fields: arrOrder })
          .then(() => {
            this.data = arr
          }).finally(() => {
            this.forceRender()
          })
      } else {
        this.data = arr
        this.forceRender()
      }
    },
    forceRender () {
      this.reRenderControl = false
      this.$nextTick(() => {
        this.reRenderControl = true
      })
    },
    canSwitch (item) {
      const { isActive } = item
      if (this.isDirect) {
        if (item.haveMultipleFields) return false

        const activeCount = this.data.filter(
          (i) => i.isActive === true
        ).length
        if (activeCount > 0 && !isActive) return false
      }
      return true
    }
  },
  watch: {
    isDirect (newValue) {
      if (newValue) {
        this.data.forEach((i) => {
          i.isActive = false
        })
      }
    }
  }
}
</script>

<style scoped>
.direct-wrapper {
  height: 1rem;
}

.ghost {
  opacity: 0;
}

.list-item-round {
  border-radius: 0.9rem !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.v-application .blue-grey.lighten-5 {
  background-color: #f4f4f4 !important;
}
</style>
