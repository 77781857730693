<template>
  <app-bar :title="$t('changePassword')" backButton>
    <v-main class="edit-profile-view">
      <v-col class="px-0 mb-10">
        <v-col class="px-5">
          <v-form
            ref="form"
            v-model="isFormValid"
            lazy-validation
          >
            <v-text-field
              filled
              dense
              rounded
              class="rounded-lg"
              height="4rem"
              :label="$t('oldPassword')"
              v-model="form.oldPassword"
              type="password"
              :rules="[rules.required, rules.min6]"
            />
            <v-text-field
              filled
              dense
              rounded
              class="rounded-lg"
              height="4rem"
              :label="$t('newPassword')"
              v-model="form.newPassword"
              type="password"
              :rules="[rules.required, rules.min6]"
            />
            <v-text-field
              filled
              dense
              rounded
              class="rounded-lg"
              height="4rem"
              :label="$t('newPasswordConfirm')"
              v-model="form.reNewPassword"
              type="password"
              :rules="[rules.required, rules.min6, rules.confirmPassword]"
            />
          </v-form>
          <div v-if="errorCode" class="subtitle-2 red--text pb-4 px-2">
            {{$t(`errorCodes.${[errorCode]}`)}}
          </div>
          <v-btn @click="changePassword" :loading="buttonLoading" block color="#2f3542" depressed class="white--text rounded-lg py-6 mb-5">{{$t('save')}}</v-btn>
        </v-col>
      </v-col>
    </v-main>
  </app-bar>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import axios from '@/utils/axios'

export default {
  components: {
    AppBar
  },
  data () {
    return {
      buttonLoading: false,
      isFormValid: false,
      form: {
        oldPassword: '',
        newPassword: '',
        reNewPassword: ''
      },
      errorCode: null,
      rules: {
        required: value => !!value || this.$t('formErrors.required'),
        min6: value => {
          return (value && value.length >= 6) || this.$t('formErrors.min6')
        },
        confirmPassword: value => value === this.form.newPassword || this.$t('newPasswordNotMatch')
      }
    }
  },
  methods: {
    changePassword () {
      if (!this.$refs.form.validate()) return null

      const userId = this.$store.state.auth.login.user.id
      axios.post(`/user/${userId}/change_password`, {
        old_password: this.form.oldPassword,
        password: this.form.newPassword
      }).then((a) => {
        this.$refs.form.reset()
        this.$router.replace({ name: 'Profile' })
      })
    }
  }
}
</script>

<style lang="scss">
.edit-profile-view {
  .v-text-field--filled.v-input--dense .v-label:not(.v-label--active) {
      margin-top: 6px !important;
  }
}

</style>

<style scoped>
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: -16px;
}
</style>
