export default {
  login: 'Log In',
  logout: 'Logout',
  register: 'Register',
  email: 'E-mail:',
  password: 'Password:',
  rePassword: 'Password (Confirm):',
  formErrors: {
    required: 'This field is required',
    email: 'Please enter a valid email address',
    min6: 'This field must be a minimum of 6 characters',
    rePassword: 'Passwords are not same',
    error: 'Please enter a valid value',
    fileSizeError: 'File is bigger than 20 MB'
  },
  profileName: 'Name:',
  location_or_title: 'Location or Title:',
  description: 'Description:',
  addNewItemInfo: 'Add contact info, links, & more to your digital business card',
  addNewItem: 'Add Content',
  direct: 'Direct',
  save: 'Save',
  errorCodes: {
    1: 'An error occurred please try again.',
    88001: 'User not found!',
    99182: 'Unknown file type',
    612184: 'Unknown file type',
    99036: 'Image save error'
  },
  myCards: 'My Cards',
  add: 'Add',
  update: 'Update',
  fieldName: 'Field Name:',
  cropImage: 'Crop Image',
  editProfile: 'Edit Profile',
  cancel: 'Cancel',
  crop: 'Crop',
  fileInput: 'File Input:',
  delete: 'Delete',
  preview: 'Preview',
  profile: 'Profile',
  settings: 'Settings',
  hideMyProfile: 'Hide My Profile',
  close: 'Close',
  pageNotFound: 'Page Not Found!',
  forgotPassword: 'Forgot Password',
  sendRecoveryEmail: 'Send Recovery Email',
  resetPassword: 'Reset Password',
  newPassword: 'New Password',
  newPasswordConfirm: 'New Password (Confirm)',
  newPasswordNotMatch: 'Your passwords not match',
  confirm: 'Confirm',
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  file: 'File',
  bank: 'Bank',
  telephone: 'Telephone',
  name: 'Name',
  address: 'Address',
  other: 'Other',
  text: 'Text',
  social_media: 'Social Media',
  communication: 'Communication',
  youtube_channel: 'YouTube Channel',
  web_url: 'Web Url',
  country: 'Country',
  selectCountry: 'Select Country',
  phoneNumber: 'Phone Number',
  example: 'Example :',
  deleteAccountTitle: 'Delete Account',
  deleteAccountMessage: 'Are you sure you want to delete your account?',
  deleteAccount: 'Delete Account',
  yes: 'Yes',
  no: 'No',
  deleteAccountSuccess: 'Your account has been deleted successfully.',
  deleteAccountError: 'An error occurred while deleting your account.',
  editCard: 'Edit',
  deleteCard: 'Delete Card',
  qrCode: 'QR Code',
  share: 'Share',
  addNewCard: 'Add New Card',
  deleteCardTitle: 'Delete Card',
  deleteCardMessage: 'Are you sure you want to delete this card?',
  deleteCardSuccess: 'Card deleted successfully.',
  deleteCardError: 'An error occurred while deleting the card.',
  editCardTitle: 'Edit Card',
  editCardMessage: 'Give your idyCard a new name',
  editCardInputPlaceholder: 'Enter your card name',
  editCardSuccess: 'Card updated successfully.',
  editCardError: 'An error occurred while updating the card.'
}
