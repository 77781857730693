import Vue from 'vue'
import VueRouter from 'vue-router'
import Authenticate from '../utils/authenticate'

import PublicCardView from '../views/Public/CardView.vue'
import PdfViewer from '../views/Public/PdfViewer.vue'
import NotFoundView from '../views/Public/NotFoundView.vue'

// AUTHENTICATION VIEWS
import LoginView from '../views/Authentication/LoginView.vue'
import ForgotPasswordView from '../views/Authentication/ForgotPasswordView.vue'
import ResetPasswordView from '../views/Authentication/ResetPasswordView.vue'
import RegisterView from '../views/Authentication/RegisterView.vue'
import LogoutView from '../views/Authentication/LogoutView.vue'

// NAVIGATION VIEWS
import BottomNavigationIndex from '../views/BottomNavigation/BottomNavigationIndex.vue'
import ProfileView from '../views/BottomNavigation/Profile/ProfileView.vue'
import EditProfileView from '../views/BottomNavigation/Profile/EditProfileView.vue'
import ChangePasswordView from '../views/BottomNavigation/Profile/ChangePasswordView.vue'
import AddItemListView from '../views/BottomNavigation/MyCards/AddItemListView.vue'
import AddItemView from '../views/BottomNavigation/MyCards/AddItemView.vue'
import EditItemView from '../views/BottomNavigation/MyCards/EditItemView.vue'
import MyCardsView from '../views/BottomNavigation/MyCards/MyCardsView.vue'
import CardView from '../views/BottomNavigation/MyCards/CardView.vue'
import SettingsView from '../views/BottomNavigation/Settings/SettingsView.vue'

import HomeView from '../views/HomeView.vue'

import ClarificationText from '../components/ClarificationText.vue'
import ClearConsentText from '../components/ClearConsent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: (to, from, next) => {
      let auth = localStorage.getItem('auth')
      if (auth) {
        auth = JSON.parse(auth)
        if (auth.user && auth.refresh_token) {
          return next({ name: 'Profile' })
        }
      }
      next()
    },
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    beforeEnter: (to, from, next) => {
      let auth = localStorage.getItem('auth')
      if (auth) {
        auth = JSON.parse(auth)
        if (auth.user && auth.refresh_token) {
          return next({ name: 'Profile' })
        }
      }
      next()
    },
    component: RegisterView
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    beforeEnter: (to, from, next) => {
      let auth = localStorage.getItem('auth')
      if (auth) {
        auth = JSON.parse(auth)
        if (auth.user && auth.refresh_token) {
          return next({ name: 'Profile' })
        }
      }
      next()
    },
    component: ForgotPasswordView
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    beforeEnter: (to, from, next) => {
      let auth = localStorage.getItem('auth')
      if (auth) {
        auth = JSON.parse(auth)
        if (auth.user && auth.refresh_token) {
          return next({ name: 'Profile' })
        }
      }
      next()
    },
    component: ResetPasswordView
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView
  },
  {
    path: '/',
    name: 'Home',
    beforeEnter: Authenticate,
    component: HomeView,
    redirect: '/login'
  },
  {
    path: '/',
    beforeEnter: Authenticate,
    component: BottomNavigationIndex,
    children: [
      {
        path: '/profile',
        name: 'Profile',
        beforeEnter: Authenticate,
        component: ProfileView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/profile/edit/:cardId',
        name: 'Edit Card Profile',
        beforeEnter: Authenticate,
        component: EditProfileView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/profile/edit',
        name: 'Edit Profile',
        beforeEnter: Authenticate,
        component: EditProfileView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/profile/change-password',
        name: 'Change Password',
        beforeEnter: Authenticate,
        component: ChangePasswordView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/my-cards',
        name: 'My Cards',
        beforeEnter: Authenticate,
        component: MyCardsView,
        meta: {
          tab: 'My Cards'
        }
      },
      {
        path: '/card/:cardId',
        name: 'Card',
        beforeEnter: Authenticate,
        component: CardView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/card/:cardId/edit/:itemId',
        name: 'Edit Item',
        beforeEnter: Authenticate,
        component: EditItemView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/card/:cardId/add',
        name: 'Add Item List',
        beforeEnter: Authenticate,
        component: AddItemListView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/card/:cardId/add/:itemId',
        name: 'Add Item',
        beforeEnter: Authenticate,
        component: AddItemView,
        meta: {
          tab: 'Profile'
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        beforeEnter: Authenticate,
        component: SettingsView,
        meta: {
          tab: 'Settings'
        }
      }
    ]
  },
  {
    path: '/clarification',
    name: 'Clarification Page',
    component: ClarificationText
  },
  {
    path: '/consent',
    name: 'Consent Page',
    component: ClearConsentText
  },
  {
    path: '/pdf',
    name: 'PDF Viewer',
    component: PdfViewer
  },
  {
    path: '/404',
    name: '404',
    component: NotFoundView
  },
  {
    path: '/:cardId',
    name: 'Public Card',
    component: PublicCardView
  },
  {
    path: '/:cardId/:cardSecret',
    name: 'Register Card',
    component: PublicCardView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
